import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Concerts {
  constructor() {
    let cache = {};
    let self = this;
    let ppp = 6;
    let vConcerts = 0;
    let total = parseInt(document.querySelector('.template-concerts:not(.concert-page) .sec2').dataset.count);
    let filterArt = false;
    let filterRegion = false;
    let filterDate = false;
    let artist = [];
    let salle = [];
    let str;
    let filterParent;
    const desktop = window.matchMedia("(min-width: 1300px)");
    const tablet = window.matchMedia("(max-width: 991px)");
    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

    let initFunc = () => {
      // initNavBillet();
      initCache();
      registerEvents();
    }

    let initCache = function() {
      cache.wrap = document.querySelector('.wrap');
      cache.toggle_concert = document.querySelector('.toggle_concert');
      cache.concert_wrap = document.querySelector('.template-concerts:not(.concert-page)');
      cache.more = document.querySelector('.template-concerts:not(.concert-page) .sec2 .more');
      cache.concerts = document.querySelector('.template-concerts:not(.concert-page) .sec2 .concert_wrap');
      cache.filterWrap = document.querySelector('.template-concerts:not(.concert-page) .sec1 .filter');
      cache.select = cache.filterWrap.querySelectorAll('.select-parent');
      cache.searchDate = document.querySelector('.template-concerts:not(.concert-page) .sec1 .filter .searchDate');
      cache.searchArtist = document.querySelector('.template-concerts:not(.concert-page) .sec1 .filter .searchArtist');
      cache.searchRegion = document.querySelector('.template-concerts:not(.concert-page) .sec1 .filter .searchRegion');
      cache.dateFilter = cache.filterWrap.querySelectorAll('.template-concerts:not(.concert-page) .filter-date li');
      cache.artistsFilter = cache.filterWrap.querySelectorAll('.template-concerts:not(.concert-page) .filter-artist li');
      cache.regionsFilter = cache.filterWrap.querySelectorAll('.template-concerts:not(.concert-page) .filter-region li');
      cache.noResult = document.querySelector('.no-result');

      cache.resetBtn = cache.filterWrap.querySelectorAll('.reset');

      cache.mobileValider = cache.concert_wrap.querySelector('.filterBottom');
      cache.mobileCloseFilter = cache.concert_wrap.querySelector('.filterTop span');
      cache.mobileOpenFilter = cache.concert_wrap.querySelector('.open_filter');
    };

    let registerEvents = function() {
      // if(desktop.matches) {
      //   cache.toggle_concert.addEventListener('mouseenter', hoverIn_toggle);
      //   cache.toggle_concert.addEventListener('mouseleave', hoverOut_toggle);
      // }
      // cache.toggle_concert.addEventListener('click', open_concerts);
      cache.more.addEventListener('click', load_posts);
      cache.searchDate.addEventListener('keyup', logSearchDate);
      cache.searchArtist.addEventListener('keyup', logSearchArtist);
      cache.searchRegion.addEventListener('keyup', logSearchRegion);
      for (let i = 0; i < cache.select.length; i++) {
        cache.select[i].addEventListener('click', open_select);
      }
      for (let i = 0; i < cache.dateFilter.length; i++) {
        cache.dateFilter[i].addEventListener('click', load_Date);
      }
      for (let i = 0; i < cache.artistsFilter.length; i++) {
        cache.artistsFilter[i].addEventListener('click', load_Artists);
      }
      for (let i = 0; i < cache.regionsFilter.length; i++) {
        cache.regionsFilter[i].addEventListener('click', load_Regions);
      }
      for (let i = 0; i < cache.resetBtn.length; i++) {
        cache.resetBtn[i].addEventListener('click', reset);
      }
      cache.mobileOpenFilter.addEventListener('click', open_filter_mobile);
      cache.mobileValider.addEventListener('click', close_filter_mobile);
      cache.mobileCloseFilter.addEventListener('click', close_filter_mobile);
    };

    // let initNavBillet = function() {
    //   document.querySelector('.nav .open_concert').addEventListener('click', open_concerts);
    //   document.querySelector('.nav_mobile .open_concert').addEventListener('click', open_concerts);
    // }

    let hoverIn_toggle = function() {

      anime({
        targets: cache.wrap,
        opacity: [1,0.9],
        left: ['0%','-2%'],
        duration: 200,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.toggle_concert,
        paddingRight: ['0%','2%'],
        duration: 200,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.concert_wrap,
        left: ['100vw','98vw'],
        duration: 200,
        easing: 'easeOutSine',
      });
    }
    let hoverOut_toggle = function() {
      anime({
        targets: cache.wrap,
        opacity: 1,
        left: '0%',
        duration: 200,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.toggle_concert,
        paddingRight: '0%',
        duration: 200,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.concert_wrap,
        left: ['98vw','100vw'],
        duration: 200,
        easing: 'easeOutSine',
      });
    }

    let reset = function() {
      filterA = document.querySelectorAll('.template-concerts:not(.concert-page) .sec1 .filter .active');
      filterNA = document.querySelectorAll('.template-concerts:not(.concert-page) .sec1 .filter .not-active');
      filterSelectA = document.querySelectorAll('.template-concerts:not(.concert-page) .sec1 .filter .active--filter');

      for (let i = 0; i < filterA.length; i++) {
        filterA[i].classList.remove('active');
      }
      for (let i = 0; i < filterNA.length; i++) {
        filterNA[i].classList.remove('not-active');
      }
      for (let i = 0; i < filterSelectA.length; i++) {
        filterSelectA[i].classList.remove('active--filter');
      }

      cache.more.style.pointerEvents = 'all';
      anime({
        targets: cache.more,
        opacity: [1],
        duration: 400,
        easing: 'easeOutSine',
      });
      ppp = 6;

      filterArt = false;
      filterRegion = false;
      filterDate = false;

      let concerts = cache.concerts.querySelectorAll('.concert, .month');
      anime({
        targets: concerts[i],
        opacity: [1,0],
        translateX: ['0%','-70%'],
        duration: 300,
        delay: (150 * i),
        easing: 'easeOutSine',
        complete: function() {
          for (let ii = 0; ii < concerts.length; ii++) {
            concerts[ii].parentNode.removeChild(concerts[ii]);
          }
          setTimeout(function() {
            load();
          },100);
        }
      });
    }

    let open_concerts = function(e) {
      e.preventDefault();
      if(!cache.concert_wrap.classList.contains('open')) {
        // cache.toggle_concert.removeEventListener('mouseenter', hoverIn_toggle);
        // cache.toggle_concert.removeEventListener('mouseleave', hoverOut_toggle);
        cache.concert_wrap.classList.add('open');
        if(desktop.matches) {
          anime({
            targets: cache.wrap,
            opacity: 1,
            left: '-100%',
            duration: 500,
            easing: 'easeOutSine',
          });

          anime({
            targets: cache.toggle_concert,
            right: [0,(window.innerWidth - 120)+'px'],
            duration: 500,
            easing: 'easeOutSine',
          });
          anime({
            targets: cache.toggle_concert,
            paddingRight: '0%',
            duration: 200,
            easing: 'easeOutSine',
          });
          anime({
            targets: cache.toggle_concert.querySelector('.close'),
            opacity: 1,
            duration: 500,
            easing: 'easeOutSine',
          });
          anime({
            targets: cache.concert_wrap,
            left: '0vw',
            duration: 500,
            easing: 'easeOutSine',
          });
        }
        else {
          anime({
            targets: cache.wrap,
            opacity: 1,
            top: '-100%',
            duration: 500,
            easing: 'easeOutSine',
          });

          anime({
            targets: cache.toggle_concert,
            bottom: (window.innerHeight - 75),
            duration: 500,
            easing: 'easeOutSine',
          });
          anime({
            targets: cache.toggle_concert.querySelector('.close'),
            opacity: 1,
            duration: 500,
            easing: 'easeOutSine',
          });
          anime({
            targets: cache.concert_wrap,
            top: '0',
            duration: 500,
            easing: 'easeOutSine',
          });
        }
      }
      else {
        closeConcert();
      }
    }

    let closeConcert = function(){
      cache.concert_wrap.classList.remove('open');
      if(desktop.matches) {
        anime({
          targets: cache.wrap,
          opacity: 1,
          left: '0%',
          duration: 500,
          easing: 'easeOutSine',
        });
        anime({
          targets: cache.toggle_concert,
          right:[(window.innerWidth - 120)+'px',0],
          duration: 500,
          easing: 'easeOutSine',
        });
        anime({
          targets: cache.toggle_concert.querySelector('.close'),
          opacity: 0,
          duration: 500,
          easing: 'easeOutSine',
        });
        anime({
          targets: cache.concert_wrap,
          left: ['0vw','100vw'],
          duration: 500,
          easing: 'easeOutSine',
          complete: function() {
            cache.toggle_concert.addEventListener('mouseenter', hoverIn_toggle);
            cache.toggle_concert.addEventListener('mouseleave', hoverOut_toggle);
          }
        });
      }
      else {
        anime({
          targets: cache.wrap,
          opacity: 1,
          top: '0%',
          duration: 500,
          easing: 'easeOutSine',
        });
        anime({
          targets: cache.toggle_concert,
          bottom:0,
          duration: 500,
          easing: 'easeOutSine',
        });
        anime({
          targets: cache.toggle_concert.querySelector('.close'),
          opacity: 0,
          duration: 500,
          easing: 'easeOutSine',
        });
        anime({
          targets: cache.concert_wrap,
          top: ['0%','100%'],
          duration: 500,
          easing: 'easeOutSine',
        });
      }
    }

    let open_filter_mobile = function() {
      cache.filterWrap.style.display = "block";
      anime({
        targets: cache.filterWrap,
        opacity: [0,1],
        translateX:[-50,0],
        duration: 400,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.toggle_concert,
        opacity: [1,0],
        translateY:[0,-50],
        duration: 400,
        easing: 'easeOutSine',
        complete: function() {
          complete: cache.toggle_concert.style.display = "none";
        }
      });
    }
    let close_filter_mobile = function() {
      anime({
        targets: cache.filterWrap,
        opacity: [1,0],
        translateX:[0, -50],
        duration: 400,
        easing: 'easeOutSine',
        complete: function() {
          cache.filterWrap.style.display = "none";
          close_select();
        }
      });
      complete: cache.toggle_concert.style.display = "block";
      anime({
        targets: cache.toggle_concert,
        opacity: [0,1],
        translateY:[-50,-0],
        duration: 400,
        easing: 'easeOutSine',
      });
    }

    let open_select = function() {
      let drop = this.parentNode.querySelector('.dropdown');
      if(desktop.matches) {
        document.querySelector('.template-concerts:not(.concert-page) .sec1').addEventListener('mouseleave', close_select);
      }
      else {
        drop.querySelector('.filterTop').addEventListener('click', close_select);
      }
      if(!this.classList.contains('active')) {
        let active = cache.filterWrap.querySelector('.select-parent.active');
        if(active) {
          active.classList.remove('active');
          anime({
            targets: active.parentNode.querySelector('.dropdown'),
            opacity: 0,
            height: 0,
            duration: 400,
            easing: 'easeOutSine',
            complete: function() {
              active.parentNode.querySelector('.dropdown').style.display = "none";
            }
          });
        }
        this.classList.add('active');
        drop.style.display = "block";
        drop.style.height = "auto";
        let height = drop.offsetHeight;
        drop.style.height = 0;

        anime({
          targets: drop,
          opacity: [0,1],
          height: [0, height],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      else {
        this.classList.remove('active');
        anime({
          targets: drop,
          opacity: 0,
          height: 0,
          duration: 400,
          easing: 'easeOutSine',
          complete: function() {
            drop.style.display = "none";
          }
        });
      }
    }
    let close_select = function() {
      document.querySelector('.template-concerts:not(.concert-page) .sec1').removeEventListener('mouseleave', close_select);
      let active = cache.filterWrap.querySelector('.select-parent.active');
      if(active) {
        active.classList.remove('active');
        anime({
          targets: active.parentNode.querySelector('.dropdown'),
          opacity: 0,
          height: 0,
          duration: 400,
          easing: 'easeOutSine',
          complete: function() {
            active.parentNode.querySelector('.dropdown').style.display = "none";
          }
        });
      }
    }

    let logSearchDate = function() {
      let filter = cache.searchDate.value.toUpperCase();
      for (i = 0; i < cache.dateFilter.length; i++) {
        a = cache.dateFilter[i];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          cache.dateFilter[i].style.display = "";
        } else {
          cache.dateFilter[i].style.display = "none";
        }
      }
    }
    let logSearchArtist = function() {
      let filter = cache.searchArtist.value.toUpperCase();
      for (i = 0; i < cache.artistsFilter.length; i++) {
        a = cache.artistsFilter[i];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          cache.artistsFilter[i].style.display = "";
        } else {
          cache.artistsFilter[i].style.display = "none";
        }
      }
    }
    let logSearchRegion = function() {
      let filter = cache.searchRegion.value.toUpperCase();
      for (i = 0; i < cache.regionsFilter.length; i++) {
        a = cache.regionsFilter[i];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          cache.regionsFilter[i].style.display = "";
        } else {
          cache.regionsFilter[i].style.display = "none";
        }
      }
    }

    let load_Date = function() {
      if(desktop.matches) {
        cache.filterWrap.style.pointerEvents = 'none';
        anime({
          targets: cache.filterWrap,
          opacity: [0.4],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      else {
        filterParent = this.parentNode;
        filterParent.style.pointerEvents = 'none';
        anime({
          targets: filterParent,
          opacity: [0.4],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      if(this.classList.contains('active')) {
        this.classList.remove('active');
        this.classList.add('not-active');
        let dateArray = document.querySelectorAll('.template-concerts:not(.concert-page) .filter-date li.active');
        if(dateArray.length == 0) {
          for (let i = 0; i < cache.dateFilter.length; i++) {
            cache.dateFilter[i].classList.remove('not-active');
          }
          if(!filterArt && !filterRegion) {
            cache.more.style.pointerEvents = 'all';
            anime({
              targets: cache.more,
              opacity: [1],
              duration: 400,
              easing: 'easeOutSine',
            });
            ppp = 6;
          }
          else {
            cache.more.style.pointerEvents = 'none';
            anime({
              targets: cache.more,
              opacity: [1,0],
              duration: 400,
              easing: 'easeOutSine',
            });
            ppp = -1;
          }
          filterDate = false;
          cache.filterWrap.querySelector('.filter-date .select-parent').classList.remove('active--filter');
        }
        else {
          cache.more.style.pointerEvents = 'none';
          anime({
            targets: cache.more,
            opacity: [1,0],
            duration: 400,
            easing: 'easeOutSine',
          });
          ppp = -1;
        }
      }
      else {
        let dateActive = document.querySelector('.template-concerts:not(.concert-page) .filter-date li.active');
        if(dateActive) {
          dateActive.classList.remove('active');
        }
        this.classList.add('active');
        this.classList.remove('not-active');
        cache.more.style.pointerEvents = 'none';
        anime({
          targets: cache.more,
          opacity: [1,0],
          duration: 400,
          easing: 'easeOutSine',
        });
        ppp = -1;
        for (let i = 0; i < cache.dateFilter.length; i++) {
          if(!cache.dateFilter[i].classList.contains('active')) {
            cache.dateFilter[i].classList.add('not-active');
          }
        }
        filterDate = true;
        cache.filterWrap.querySelector('.filter-date .select-parent').classList.add('active--filter');
      }

      vConcerts = 0;
      date = [];
      date.push((this.dataset.year+this.dataset.month+'01'));
      date.push((this.dataset.year+this.dataset.month+'31'));

      if(!cache.concerts.classList.contains('.filtered')) {
        let concerts = cache.concerts.querySelectorAll('.concert, .month');
        if(concerts.length > 0) {
          for (let i = 0; i < concerts.length; i++) {
            if(i == concerts.length-1) {
              anime({
                targets: concerts[i],
                opacity: [1,0],
                translateX: ['0%','-70%'],
                duration: 300,
                delay: (150 * i),
                easing: 'easeOutSine',
                complete: function() {
                  for (let ii = 0; ii < concerts.length; ii++) {
                    concerts[ii].parentNode.removeChild(concerts[ii]);
                  }
                  setTimeout(function() {
                    load();
                  },100);
                }
              });
            }
            else {
              anime({
                targets: concerts[i],
                opacity: [1,0],
                translateX: ['0%','-70%'],
                duration: 500,
                delay: (150 * i),
                easing: 'easeOutSine',
              });
            }
          }
        }
        else {
          load();
        }
      }
    }

    let load_Regions = function() {
      if(desktop.matches) {
        cache.filterWrap.style.pointerEvents = 'none';
        anime({
          targets: cache.filterWrap,
          opacity: [0.4],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      else {
        filterParent = this.parentNode;
        filterParent.style.pointerEvents = 'none';
        anime({
          targets: filterParent,
          opacity: [0.4],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      if(this.classList.contains('active')) {
        this.classList.remove('active');
        this.classList.add('not-active');
        let regionArray = document.querySelectorAll('.template-concerts:not(.concert-page) .filter-region li.active');
        cache.filterWrap.querySelector('.filter-region .select-parent .nb').innerHTML = regionArray.length;
        if(regionArray.length == 0) {
          for (let i = 0; i < cache.regionsFilter.length; i++) {
            cache.regionsFilter[i].classList.remove('not-active');
          }
          if(!filterArt && !filterDate) {
            cache.more.style.pointerEvents = 'all';
            anime({
              targets: cache.more,
              opacity: [1],
              duration: 400,
              easing: 'easeOutSine',
            });
            ppp = 6;
          }
          else {
            cache.more.style.pointerEvents = 'none';
            anime({
              targets: cache.more,
              opacity: [1,0],
              duration: 400,
              easing: 'easeOutSine',
            });
            ppp = -1;
          }
          filterRegion = false;
          cache.filterWrap.querySelector('.filter-region .select-parent').classList.remove('active--filter');
        }
        else {
          cache.more.style.pointerEvents = 'none';
          anime({
            targets: cache.more,
            opacity: [1,0],
            duration: 400,
            easing: 'easeOutSine',
          });
          ppp = -1;
        }
      }
      else {
        this.classList.add('active');
        this.classList.remove('not-active');
        let regionArray = document.querySelectorAll('.template-concerts:not(.concert-page) .filter-region li.active');
        cache.filterWrap.querySelector('.filter-region .select-parent .nb').innerHTML = regionArray.length;
        cache.more.style.pointerEvents = 'none';
        anime({
          targets: cache.more,
          opacity: [1,0],
          duration: 400,
          easing: 'easeOutSine',
        });
        ppp = -1;
        if(regionArray.length == 1) {
          for (let i = 0; i < cache.regionsFilter.length; i++) {
            if(!cache.regionsFilter[i].classList.contains('active')) {
              cache.regionsFilter[i].classList.add('not-active');
            }
          }
          filterRegion = true;
          cache.filterWrap.querySelector('.filter-region .select-parent').classList.add('active--filter');
        }
      }

      vConcerts = 0;
      salle = [];
      for (let i = 0; i < regionArray.length; i++) {
        let salleArray = regionArray[i].dataset.salleid.split(",");
        for (let ii = 0; ii < salleArray.length; ii++) {
          salle.push(parseInt(salleArray[ii]));
        }
      }

      if(!cache.concerts.classList.contains('.filtered')) {
        let concerts = cache.concerts.querySelectorAll('.concert, .month');
        if(concerts.length > 0) {
          for (let i = 0; i < concerts.length; i++) {
            if(i == concerts.length-1) {
              anime({
                targets: concerts[i],
                opacity: [1,0],
                translateX: ['0%','-70%'],
                duration: 300,
                delay: (150 * i),
                easing: 'easeOutSine',
                complete: function() {
                  for (let ii = 0; ii < concerts.length; ii++) {
                    concerts[ii].parentNode.removeChild(concerts[ii]);
                  }
                  setTimeout(function() {
                    load();
                  },100);
                }
              });
            }
            else {
              anime({
                targets: concerts[i],
                opacity: [1,0],
                translateX: ['0%','-70%'],
                duration: 500,
                delay: (150 * i),
                easing: 'easeOutSine',
              });
            }
          }
        }
        else {
          load();
        }
      }
    }

    let load_Artists = function() {
      if(desktop.matches) {
        cache.filterWrap.style.pointerEvents = 'none';
        anime({
          targets: cache.filterWrap,
          opacity: [0.4],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      else {
        filterParent = this.parentNode;
        filterParent.style.pointerEvents = 'none';
        anime({
          targets: filterParent,
          opacity: [0.4],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      if(this.classList.contains('active')) {
        this.classList.remove('active');
        this.classList.add('not-active');
        let artistArray = document.querySelectorAll('.template-concerts:not(.concert-page) .filter-artist li.active');
        cache.filterWrap.querySelector('.filter-artist .select-parent .nb').innerHTML = artistArray.length;
        if(artistArray.length == 0) {
          for (let i = 0; i < cache.artistsFilter.length; i++) {
            cache.artistsFilter[i].classList.remove('not-active');
          }
          if(!filterRegion && !filterDate) {
            cache.more.style.pointerEvents = 'all';
            anime({
              targets: cache.more,
              opacity: [1],
              duration: 400,
              easing: 'easeOutSine',
            });
            ppp = 6;
          }
          else {
            cache.more.style.pointerEvents = 'none';
            anime({
              targets: cache.more,
              opacity: [1,0],
              duration: 400,
              easing: 'easeOutSine',
            });
            ppp = -1;
          }
          filterArt = false;
          cache.filterWrap.querySelector('.filter-artist .select-parent').classList.remove('active--filter');
        }
        else {
          cache.more.style.pointerEvents = 'none';
          anime({
            targets: cache.more,
            opacity: [1,0],
            duration: 400,
            easing: 'easeOutSine',
          });
          ppp = -1;
        }
      }
      else {
        this.classList.add('active');
        this.classList.remove('not-active');
        let artistArray = document.querySelectorAll('.template-concerts:not(.concert-page) .filter-artist li.active');
        cache.more.style.pointerEvents = 'none';
        cache.filterWrap.querySelector('.filter-artist .select-parent .nb').innerHTML = artistArray.length;
        anime({
          targets: cache.more,
          opacity: [1,0],
          duration: 400,
          easing: 'easeOutSine',
        });
        ppp = -1;
        if(artistArray.length == 1) {
          for (let i = 0; i < cache.artistsFilter.length; i++) {
            if(!cache.artistsFilter[i].classList.contains('active')) {
              cache.artistsFilter[i].classList.add('not-active');
            }
          }
          filterArt = true;
          cache.filterWrap.querySelector('.filter-artist .select-parent').classList.add('active--filter');
        }
      }

      vConcerts = 0;
      artist = [];
      for (let i = 0; i < artistArray.length; i++) {
        let id = parseInt(artistArray[i].dataset.artistid);
        artist.push(id);
      }

      if(!cache.concerts.classList.contains('.filtered')) {
        let concerts = cache.concerts.querySelectorAll('.concert, .month');
        if(concerts.length > 0) {
          for (let i = 0; i < concerts.length; i++) {
            if(i == concerts.length-1) {
              anime({
                targets: concerts[i],
                opacity: [1,0],
                translateX: ['0%','-70%'],
                duration: 300,
                delay: (150 * i),
                easing: 'easeOutSine',
                complete: function() {
                  for (let ii = 0; ii < concerts.length; ii++) {
                    concerts[ii].parentNode.removeChild(concerts[ii]);
                  }
                  setTimeout(function() {
                    load();
                  },100);
                }
              });
            }
            else {
              anime({
                targets: concerts[i],
                opacity: [1,0],
                translateX: ['0%','-70%'],
                duration: 500,
                delay: (150 * i),
                easing: 'easeOutSine',
              });
            }
          }
        }
        else {
          load();
        }
      }
    }

    let load_posts = function() {
      vConcerts += 6;
      if( (total - 6) < (vConcerts + 6)) {
        ppp = total-vConcerts;
        cache.more.style.pointerEvents = 'none';
        anime({
          targets: cache.more,
          opacity: [1,0],
          duration: 400,
          easing: 'easeOutSine',
        });
      }
      load();
    }

    let load = function() {
      str = '&vConcerts=' + vConcerts + '&ppp=' + ppp;
      if(filterDate) {
        str += '&date1=' + date[0] +'&date2=' + date[1];
      }
      if(filterArt) {
        str += '&artist=' + artist;
      }
      if(filterRegion) {
        str += '&salle=' + salle;
      }
      str += '&action=more_concert_ajax';

      if(filterDate || filterArt || filterRegion) {
        for (let i = 0; i < cache.resetBtn.length; i++) {
          cache.resetBtn[i].classList.add('active');
        }
      }
      if(!filterDate && !filterArt && !filterRegion) {
        for (let i = 0; i < cache.resetBtn.length; i++) {
          cache.resetBtn[i].classList.remove('active');
        }
      }

      jQuery.ajax({
        type: "GET",
        dataType: "html",
        url: ajaxurl,
        data: str,
        success: function(data){
          data = jQuery(data);
          if(data.length){
            cache.noResult.style.display = "none";
            for (let i = 0; i < data.length; i++) {
              let prevConcert = document.querySelector('.template-concerts:not(.concert-page) .sec2 .concert:last-child');
              let month = data[i].dataset.month;

              if(prevConcert) {
                let prevMonth = document.querySelector('.template-concerts:not(.concert-page) .sec2 .concert:last-child').dataset.month;
                if(month !== prevMonth) {
                  let div = document.createElement("div");
                  let span = document.createElement("span");
                  span.innerHTML = month;
                  div.classList.add('month');
                  div.append(span);
                  cache.concerts.append(div);
                }
                cache.concerts.append(data[i]);
                setTimeout(function() {
                  let font = new Font();
                }, 100);
              }
              else {
                let div = document.createElement("div");
                let span = document.createElement("span");
                span.innerHTML = month;
                div.classList.add('month');
                div.append(span);
                cache.concerts.append(div);
                cache.concerts.append(data[i]);
                setTimeout(function() {
                  let font = new Font();
                }, 100);
              }

              anime({
                targets: cache.concerts.querySelector('.concert:last-child'),
                opacity: [0,1],
                translateX: ['-70%','0%'],
                duration: 700,
                delay: (150 * i),
                easing: 'easeOutSine',
              });

            }
            let hoverImg = new HoverImg();
            if(desktop.matches) {
              cache.filterWrap.style.pointerEvents = 'all';
              anime({
                targets: cache.filterWrap,
                opacity: 1,
                duration: 400,
                easing: 'easeOutSine',
              });
            }
            else {
              filterParent.style.pointerEvents = 'all';
              anime({
                targets: filterParent,
                opacity: 1,
                duration: 400,
                easing: 'easeOutSine',
              });
            }

          }
          else {
            cache.noResult.style.display = "block";
            if(desktop.matches) {
              cache.filterWrap.style.pointerEvents = 'all';
              anime({
                targets: cache.filterWrap,
                opacity: 1,
                duration: 400,
                easing: 'easeOutSine',
              });
            }
            else {
              filterParent.style.pointerEvents = 'all';
              anime({
                targets: filterParent,
                opacity: 1,
                duration: 400,
                easing: 'easeOutSine',
              });
            }
          }
        },
        error : function(jqXHR, textStatus, errorThrown) {
          if(desktop.matches) {
            cache.filterWrap.style.pointerEvents = 'all';
            anime({
              targets: cache.filterWrap,
              opacity: 1,
              duration: 400,
              easing: 'easeOutSine',
            });
          }
          else {
            filterParent.style.pointerEvents = 'all';
            anime({
              targets: filterParent,
              opacity: 1,
              duration: 400,
              easing: 'easeOutSine',
            });
          }
        }

      });
      return false;
    }


    initFunc();
  };
}
export { Concerts }
