import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class GlobalJs {
  constructor() {
  let cache = {};
  let self = this;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    if(document.querySelector('.btn')) {
      cache.btn = document.querySelectorAll('.btn');
      initBtn();
    }
  };

  var registerEvents = function() {

  };

  var initBtn = function() {
    for (var i = 0; i < cache.btn.length; i++) {
      var width = cache.btn[i].offsetWidth;
      if(width > 0 && !cache.btn[i].querySelector('.bar') && !cache.btn[i].classList.contains('btn-cancel')) {
        var nbBar = Math.ceil(width/15);
        var ii = nbBar;
        while (ii > 0) {
          var span = document.createElement("span");
          span.classList.add('bar');
          span.style.left = ((nbBar - ii)*15)+'px';
          cache.btn[i].append(span);
          ii--;
        }
        cache.btn[i].addEventListener('mouseenter', hoverInBtn);
      }
    }
  }

  var hoverInBtn = function() {
    this.removeEventListener('mouseenter', hoverInBtn);
    var bar = this.querySelectorAll('.bar');
    for (var i = 0; i < bar.length; i++) {
      anime({
        targets: bar[i],
        translateY: ['100%','0%'],
        delay: Math.floor((Math.random() * 200) + 0),
        duration: 300,
        easing: 'easeOutSine',
      });

      if(i == bar.length-1){
        this.addEventListener('mouseleave', hoverOutBtn);
      }
    }
  }
  var hoverOutBtn = function() {
    this.removeEventListener('mouseleave', hoverOutBtn);
    var bar = this.querySelectorAll('.bar');
    for (var i = 0; i < bar.length; i++) {
      anime({
        targets: bar[i],
        translateY: ['0%','100%'],
        delay: Math.floor((Math.random() * 200) + 0),
        duration: 200,
        easing: 'easeOutSine',
      });
      if(i == bar.length-1){
        this.addEventListener('mouseenter', hoverInBtn);
      }
    }
  }


  initFunc();
};
}
export { GlobalJs }
