import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Apropos {
  constructor() {  let cache = {};
  let self = this;
  let sliderIndex = 0;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
    bandeau();

    }

  let initCache = function() {
    cache.slider = document.querySelector('.slider_ap');
    cache.slides = cache.slider.querySelectorAll('.slide');
    cache.slideNxt = document.querySelector('.sec1 .nxt');
    cache.sliderLeft = cache.slider.querySelector('.map-left');
    cache.sliderRight = cache.slider.querySelector('.map-right');

    cache.bandeau = document.querySelectorAll('.bandeau');
  };

  let registerEvents = function() {
    cache.slider.addEventListener('mouseover', hoverSlider);
    cache.sliderRight.addEventListener("click", slideNxt);
    cache.sliderLeft.addEventListener("click", slidePrev);
    cache.sliderRight.addEventListener("mouseover", hoverNxt);
    cache.sliderLeft.addEventListener("mouseover", hoverPrev);
    var hammertime = new Hammer(cache.slider);
    hammertime.on('swipe', function(ev) {
      if(ev.direction == 2) {
        slideNxt();
      }
      if(ev.direction == 4) {
        slidePrev();
      }
    });
  };

  var slideNxt = function() {
    cache.slideNxt.removeEventListener("click", slideNxt);
    var currentSlide = cache.slides[sliderIndex];
    if (sliderIndex < cache.slides.length-2) {
      sliderIndex++;
      var nxtIndex = sliderIndex + 1;
    }
    else if (sliderIndex < cache.slides.length-1) {
      sliderIndex++;
      var nxtIndex = 0;
    }
    else {
      sliderIndex = 0;
      var nxtIndex = 1;
    }

    var slideTlCurrent = anime.timeline({
      duration: 900,
      complete: function() {
        cache.slideNxt.addEventListener("click", slideNxt);
      }
    });

    slideTlCurrent
    .add({
        targets: currentSlide,
        scale: 0.95,
        duration: 200,
        easing: 'easeInSine',
    })
    .add({
        targets: currentSlide,
        translateX: ['0%','-110%'],
        easing: 'easeInOutSine',
    })
    .add({
        targets: currentSlide,
        scale: 1,
        duration: 200,
        easing: 'easeOutSine',
    });


    anime.set(cache.slides[sliderIndex], { translateX: '110%' });

    var slideTl = anime.timeline({
      duration: 900
    });

    slideTl
    .add({
        targets: cache.slides[sliderIndex],
        scale: 0.95,
        duration: 200,
        easing: 'easeInSine',
    })
    .add({
        targets: cache.slides[sliderIndex],
        translateX: ['110%','0%'],
        easing: 'easeInOutSine',
    })
    .add({
        targets: cache.slides[sliderIndex],
        scale: 1,
        duration: 200,
        easing: 'easeOutSine',
    });


    anime.set(cache.slides[nxtIndex], { translateX: '220%' });
    var slideTlNxt = anime.timeline({
      duration: 900
    });

    slideTlNxt
    .add({
        targets: cache.slides[nxtIndex],
        scale: 0.95,
        duration: 200,
        easing: 'easeInSine',
    })
    .add({
        targets: cache.slides[nxtIndex],
        translateX: ['220%','110%'],
        easing: 'easeInOutSine',
    })
    .add({
        targets: cache.slides[nxtIndex],
        scale: 1,
        duration: 200,
        easing: 'easeOutSine',
    });

  };

  var slidePrev = function() {
    cache.sliderLeft.removeEventListener("click", slidePrev);
    var currentSlide = cache.slides[sliderIndex];

    if ((sliderIndex > 0) && (sliderIndex < cache.slides.length-1)) {
      var nxtIndex = sliderIndex + 1;
      sliderIndex--;
    }
    else if (sliderIndex == cache.slides.length-1) {
      sliderIndex--;
      var nxtIndex = 0;
    }
    else if (sliderIndex == 0) {
      var nxtIndex = 1;
      sliderIndex = cache.slides.length-1;
    }


    var slideTlCurrent = anime.timeline({
      duration: 900,
      complete: function() {
        cache.sliderLeft.addEventListener("click", slidePrev);
      }
    });

    slideTlCurrent
    .add({
        targets: currentSlide,
        scale: 0.95,
        duration: 200,
        easing: 'easeInSine',
    })
    .add({
        targets: currentSlide,
        translateX: ['0%','110%'],
        easing: 'easeInOutSine',
    })
    .add({
        targets: currentSlide,
        scale: 1,
        duration: 200,
        easing: 'easeOutSine',
    });


    anime.set(cache.slides[sliderIndex], { translateX: '-110%' });

    var slideTl = anime.timeline({
      duration: 900
    });

    slideTl
    .add({
        targets: cache.slides[sliderIndex],
        scale: 0.95,
        duration: 200,
        easing: 'easeInSine',
    })
    .add({
        targets: cache.slides[sliderIndex],
        translateX: ['-110%', '0%'],
        easing: 'easeInOutSine',
    })
    .add({
        targets: cache.slides[sliderIndex],
        scale: 1,
        duration: 200,
        easing: 'easeOutSine',
    });


    anime.set(cache.slides[nxtIndex], { translateX: '110%' });
    var slideTlNxt = anime.timeline({
      duration: 900
    });

    slideTlNxt
    .add({
        targets: cache.slides[nxtIndex],
        scale: 0.95,
        duration: 200,
        easing: 'easeInSine',
    })
    .add({
        targets: cache.slides[nxtIndex],
        translateX: ['110%','220%'],
        easing: 'easeInOutSine',
    })
    .add({
        targets: cache.slides[nxtIndex],
        scale: 1,
        duration: 200,
        easing: 'easeOutSine',
    });

  };

  var hoverNxt = function() {
    if(cache.slideNxt.classList.contains('reverse')) {
      cache.slideNxt.classList.remove('reverse');
    }
  }
  var hoverPrev = function() {
    if(!cache.slideNxt.classList.contains('reverse')) {
      cache.slideNxt.classList.add('reverse');
    }
  }

  var hoverSlider = function() {
    window.addEventListener('mousemove', moveIcon);
    this.removeEventListener('mouseover', hoverSlider);
    this.addEventListener('mouseleave', outSlider);
    anime({
      targets: cache.slideNxt,
      opacity: [0,1],
      duration: 300,
      easing: 'easeInOutQuad',
    });
  }

  var outSlider = function() {
    window.removeEventListener('mousemove', moveIcon);
    this.addEventListener('mouseover', hoverSlider);
    this.removeEventListener('mouseleave', outSlider);
    anime({
      targets: cache.slideNxt,
      opacity: [1,0],
      duration: 300,
      easing: 'easeInOutQuad',
    });
  }

  var moveIcon = function(e) {
    cache.slideNxt.style.top = e.pageY + 'px';
    cache.slideNxt.style.left = e.pageX + 'px';
    // if(e.pageX > (window.innerWidth-cache.slideNxt.offsetWidth)) {
    //   if(!cache.slideNxt.classList.contains('reverse')) {
    //     cache.slideNxt.classList.add('reverse');
    //   }
    // }
    // if(e.pageX < (window.innerWidth-cache.slideNxt.offsetWidth)) {
    //   if(img.classList.contains('reverse')) {
    //     cache.slideNxt.classList.remove('reverse');
    //   }
    // }
  }



  var bandeau = function() {

    for (var i = 0; i < cache.bandeau.length; i++) {
      var bandeau = cache.bandeau[i];
      anime({
        targets: bandeau,
        translateX: ['0%', '-100%'],
        duration: 6000,
        easing: 'linear',
        loop: true,
      });
    }

  }


  initFunc();
  };
}
export { Apropos }
