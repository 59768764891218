import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Contact {
  constructor() {
  let cache = {};
  let self = this;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    cache.formsContainer = document.querySelector('.forms_container');
    cache.forms_wrap = cache.formsContainer.querySelector('.forms_wrap');
    cache.btn = cache.formsContainer.querySelectorAll('.btn');

    cache.form1 = cache.formsContainer.querySelector('.form1');
    cache.form2 = cache.formsContainer.querySelector('.form2');
  };

  var registerEvents = function() {
    for (var i = 0; i < cache.btn.length; i++) {
      cache.btn[i].addEventListener('click', switchForm);
    }
  };


  var switchForm = function() {
    var currentBtn = cache.formsContainer.querySelector('.btn.active');
    var currentForm = cache.formsContainer.querySelector('.'+currentBtn.dataset.form);
    var currentHeight = cache.forms_wrap.offsetHeight;

    var targetForm = cache.formsContainer.querySelector('.'+this.dataset.form);
    targetForm.style.display = "block";
    var targetHeight = targetForm.offsetHeight;

    currentBtn.classList.remove('active');
    this.classList.add('active');

    anime.set(targetForm, { translateX: 100, opacity: 0 });

    anime({
      targets: currentForm,
      translateX: [0, -100],
      opacity: [1,0],
      duration: 500,
      easing: 'linear',
      complete: function() {
        currentForm.style.display = "none";
      }
    });

    anime({
      targets: targetForm,
      translateX: [100,0],
      opacity: [0,1],
      duration: 500,
      easing: 'linear',
    });

    anime({
      targets: cache.forms_wrap,
      height: [currentHeight,targetHeight+180],
      duration: 500,
      easing: 'linear',
    });

  };


  initFunc();
};
}
export { Contact }
