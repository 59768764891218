import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Account {
  constructor() {
  let cache = {};
  let self = this;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    cache.formWrap = document.querySelector('.content .form_wrap');
    cache.aside = document.querySelector('.content aside');
    cache.options = cache.aside.querySelectorAll('.option');
    cache.forms = cache.formWrap.querySelectorAll('.form_part');
  };

  let registerEvents = function() {
    acf.doAction( 'prepare' );

    for (let i = 0; i < cache.options.length; i++) {
      cache.options[i].addEventListener('click', formPart);
    }
  };

  let formPart = function() {
    let targetFrorm = this.dataset.form;
    let activeForm = cache.aside.querySelector('.option.active').dataset.form;
    cache.aside.querySelector('.option.active').classList.remove('active');
    this.classList.add('active');

    if(targetFrorm == 0) {
      cache.forms[targetFrorm].style.pointerEvents = "all";
    }
    else {
      cache.forms[targetFrorm].style.display = "block";
    }

    anime({
      targets: cache.forms[targetFrorm],
      opacity: [0,1],
      translateX: [100, 0],
      duration: 400,
      easing: 'easeInOutQuad',
    });

    anime({
      targets: cache.forms[activeForm],
      translateX: [0, -100],
      opacity: [1,0],
      duration: 500,
      easing: 'easeOutSine',
      complete: function() {
        if(activeForm == 0) {
          cache.forms[activeForm].style.pointerEvents = "none";
        }
        else {
          cache.forms[activeForm].style.display = "none";
        }
      }
    });

    anime({
      targets: scrollElement,
      scrollTop: cache.formWrap.offsetTop-120,
      delay: 200,
      duration: 500,
      easing: 'easeInOutSine',
    });
    anime({
      targets: cache.formWrap.querySelector('form'),
      delay: 200,
      height: (cache.forms[targetFrorm].offsetHeight + 110),
      duration: 500,
      easing: 'easeOutSine',
    });
  }

  initFunc();
};
}
export { Account }
