import { enableScroll, disableScroll } from '../utils/utils';
import anime from '../libs/anime';
import Hammer from '../libs/hammer';
import globalVar from '../utils/globalVar';

class Ajax {
  constructor() {
    let cache = {};
    let self = this;
    let getUrl = window.location;
    let getUrlHref = getUrl.href;

    // Construction ajustée de baseUrl
    let baseUrl = `${getUrl.protocol}//${getUrl.host}${getUrl.pathname.split('/').slice(0, -1).join('/')}`;

    let liens = [];
    let loaderHome = true; // Initialiser loaderHome à true
    const desktop = window.matchMedia("(min-width: 1300px)");
    const tablet = window.matchMedia("(max-width: 991px)");
    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

    let initFunc = () => {
      initCache();
      registerEvents();
      if (loaderHome) {
        removeLoader();
      }
    };

    let initCache = function() {
      cache.loader = document.querySelector('.loader');
      cache.transition = document.querySelector('.transition');
      cache.wrap = document.querySelector('.wrap');
      cache.nav = document.querySelector('header');
      cache.header_link = document.querySelector('.nav .open_concert');
      cache.header_linkM = document.querySelector('.nav_mobile .open_concert');
      cache.footerNl = document.querySelector('footer .submitNl');
      cache.footerEmailNl = document.querySelector('footer #opt_email');

      if (baseUrl != null && baseUrl.length > 0 && baseUrl.charAt(baseUrl.length - 1) == '/') {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1);
      }
    };

    let registerEvents = function() {
      window.addEventListener('popstate', back);
      cache.footerNl.addEventListener("click", submitNl);
    };

    let submitNl = function() {
      let email_nl = cache.footerEmailNl.value; // Ajouter let pour déclarer la variable
    };

    let removeLoader = function(e) {
      loaderHome = false;
      anime({
        targets: scrollElement,
        scrollTop: 0,
        duration: 10,
      });
      anime({
        targets: cache.loader,
        translateX: [0, '100%'],
        delay: 500, // Maintenir le délai
        duration: 1500, // Ajuster la durée de l'animation ici
        easing: 'easeInOutExpo',
        complete: function() {
            cache.loader?.remove();
        }
      });
      anime({
        targets: cache.wrap,
        delay: 500, // Maintenir le délai
        left: ['-100%', 0],
        duration: 1500, // Ajuster la durée de l'animation ici
        easing: 'easeInOutExpo',
      });
    };

    let back = function(event) {
      disableScroll();
      event.preventDefault();
      let href = this.href;

      cache.transition.querySelector('.txt').innerHTML = "Alias";

      cache.transition.style.display = "block";
      anime({
        targets: cache.transition,
        translateY: ['-100%', '0%'],
        duration: 300, // Réduire la durée
        easing: 'easeInCubic',
      });
      setTimeout(function() {
        ajaxFunc(href);
      }, 600); // Réduire le délai
      if (document.querySelector('.template-concerts').classList.contains('open')) {
        closeConcert();
      }
    };

    let ajaxFunc = function(url) {
      jQuery.ajax({
        url: url,
        processData: true,
        dataType: 'html',
        success: function(data) {
          let title = jQuery(data).filter('title').text();
          document.title = title;
          let contenu = jQuery(jQuery.parseHTML(data)).filter("div#contenu");
          let head = jQuery(jQuery.parseHTML(data)).find("head");
          let footer = jQuery(jQuery.parseHTML(data)).filter("footer");
          let header = jQuery(jQuery.parseHTML(data)).filter("#nav_header");
          let parser = new DOMParser();
          let doc = parser.parseFromString(data, "text/html");
          let docClass = doc.body.getAttribute('class');
          parser = doc = null;
          jQuery('#contenu').fadeOut('200', function() {
            jQuery('body').removeClass();
            jQuery('body').addClass(docClass);
            jQuery('head').html(head.html());
            jQuery('footer').html(footer.html());
            jQuery('#nav_header').html(header.html());

            jQuery(this).html(jQuery(contenu).html()).fadeIn('200');
            window.scrollTo(0, 0);
            enableScroll();
            window.removeEventListener('popstate', back);

            if (window["App"] && typeof window["App"] === 'function') {
              let CurrentObject = window["App"];
              let app = new CurrentObject();
            } else {
              console.error("App is not defined or not a constructor");
            }

            // initNavBillet();

            anime({
              targets: cache.transition,
              translateY: ['0%', '100%'],
              duration: 300, // Réduire la durée
              easing: 'easeOutCubic',
              complete: function() {
                if (document.querySelector('.template-contact .wpcf7')) {
                  wpcf7.init(document.querySelector('.form1 form'));
                  wpcf7.init(document.querySelector('.form2 form'));
                } else if (document.querySelector('.template-single-concours .wpcf7')) {
                  wpcf7.init(document.querySelector('.form_wrap form'));
                }
              }
            });
          });
        }
      });
    };

    initFunc();
  }
}

export { Ajax };
