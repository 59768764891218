import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Nav {
  constructor() {  let cache = {};
  let self = this;
  let sliderIndex = 0;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    cache.nav = document.querySelector('.nav');
    cache.navmobileBtn = document.querySelector('.nav_btn_mobile');
    cache.navmobile = document.querySelector('.nav_mobile');
    cache.header_linkM = document.querySelector('.nav_mobile .open_concert');
  };

  let registerEvents = function() {

    cache.navmobileBtn.addEventListener("click", openNav);
    // cache.header_linkM.addEventListener("click", closeNav);

  };


  let openNav = function() {
    cache.navmobileBtn.removeEventListener("click", openNav);
    cache.navmobile.style.display="block";
    cache.navmobileBtn.classList.add('open');
    document.querySelector('body').classList.add('overflow');
    document.querySelector('html').classList.add('overflow');

    anime({
      targets: cache.navmobile,
      opacity: [0,1],
      translateX: [-50,0],
      duration: 400,
      easing: 'easeOutQuad',
      complete: function() {
        cache.navmobileBtn.addEventListener("click", closeNav);
      }
    });
  }

  let closeNav = function() {
    cache.navmobileBtn.removeEventListener("click", closeNav);
    cache.navmobileBtn.classList.remove('open');
    document.querySelector('body').classList.remove('overflow');
    document.querySelector('html').classList.remove('overflow');

    anime({
      targets: cache.navmobile,
      opacity: [1,0],
      translateX: [0,-50],
      duration: 400,
      easing: 'easeOutQuad',
      complete: function() {
        cache.navmobileBtn.addEventListener("click", openNav);
        cache.navmobile.style.display="none";
      }
    });
  }

  initFunc();
};
}
export { Nav }
