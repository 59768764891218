import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class QuatreCentQuatre {
  constructor() {
  let cache = {};
  let self = this;
  let sliderIndex = 0;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    // registerEvents();
    bandeau();
  }

  let initCache = function() {
    cache.bandeau = document.querySelectorAll('.bandeau');
  };
  //
  // var registerEvents = function() {
  //
  // };

  var bandeau = function() {

    for (var i = 0; i < cache.bandeau.length; i++) {
      var bandeau = cache.bandeau[i];
      anime({
        targets: bandeau,
        translateX: ['0%', '-100%'],
        duration: 6000,
        easing: 'linear',
        loop: true,
      });
    }

  }


  initFunc();
};
}
export { QuatreCentQuatre }
