import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Font {
  constructor() {  let cache = {};
  let self = this;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    if(document.querySelector('.date')) {
      cache.date = document.querySelectorAll('.date');
    }
  };

  let registerEvents = function() {
    if(document.querySelector('.date')) {
      for (let i = 0; i < cache.date.length; i++) {
        if(!cache.date[i].classList.contains('format')) {
          let width = cache.date[i].querySelector('.date_l').offsetWidth;

          let dateDayW = cache.date[i].querySelector('.date_day span').offsetWidth;
          let dateDayL = cache.date[i].querySelector('.date_day span').innerHTML.length;
          let wdthD = calcWdth(width, dateDayW, dateDayL);
          cache.date[i].querySelector('.date_day').style.fontVariationSettings='"wght" 600, "wdth" '+ wdthD +', "ital" 0';

          let dateMonthW = cache.date[i].querySelector('.date_month span').offsetWidth;
          let dateMonthL = cache.date[i].querySelector('.date_month span').innerHTML.length;
          let wdthM = calcWdth(width, dateMonthW, dateMonthL);
          cache.date[i].querySelector('.date_month').style.fontVariationSettings='"wght" 700, "wdth" '+ wdthM +', "ital" 0';

          cache.date[i].classList.add('format');
        }
      }
    }
  };

  let calcWdth = function(containerWidth, txtWidth, nbLetter) {
    let calc = ((((containerWidth-txtWidth-(txtWidth/nbLetter))*1000)/containerWidth)/nbLetter);
    return calc;
  }

  initFunc();
};
}
export { Font }
