import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Concours {
  constructor() {
  let cache = {};
  let self = this;
  let scrollPos = 0;
  let scrollPrev = 0;
  let index = 0;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    if(document.querySelector(".template-archive-concours")) {
      cache.thumb = document.querySelectorAll('.concours_thumb');
      cache.cursorSpan = document.querySelectorAll('.cursor .wrap_cursor span');
    }

    else {
      cache.content = document.querySelector('.content-wrap');
      cache.stepStarter = cache.content.querySelector('.step_starter');
      cache.btnStart = cache.stepStarter.querySelector('.start_form');
      cache.form = cache.content.querySelector('.form_wrap');
      cache.formStep = cache.form.querySelectorAll('.step');
      cache.nxtBtn = cache.form.querySelector('.nav_form .btn')
      cache.submitBtn = cache.form.querySelector('.nav_form input[type=submit]')
      cache.radio = cache.form.querySelectorAll('input[type=radio]');
      cache.input = cache.form.querySelectorAll('input[type=text],input[type=email],input[type=number]');
      cache.formEl = cache.form.querySelector( '.wpcf7' );
      cache.formThanksImg = document.querySelector( '.img .img_thanks' );
      cache.formThanks = cache.content.querySelector('.step_thanks');
    }
  };

  var registerEvents = function() {
    if(document.querySelector(".template-archive-concours")) {
      for (var i = 0; i < cache.thumb.length; i++) {
        cache.thumb[i].addEventListener('mouseenter', showCursor);
      }
      for (var i = 0; i < cache.cursorSpan.length; i++) {
        anime({
          targets: cache.cursorSpan,
          translateX: ['0%', '-118%'],
          duration: 4000,
          easing: 'linear',
          loop: true,
        });
      }
    }

    else {
      for (var i = 0; i < cache.radio.length; i++) {
        cache.radio[i].addEventListener('click', nxtActive);
        cache.radio[i].checked = false;
      }
      for (var i = 0; i < cache.input.length; i++) {
        cache.input[i].addEventListener("keypress", nxtActive);
      }
      cache.nxtBtn.addEventListener('click', nxtStep);

      cache.form.querySelector('.total_step').innerHTML = cache.formStep.length;
      cache.btnStart.addEventListener('click', startForm);
      cache.formStep[0].style.display = "block";
      cache.formStep[0].style.opacity = 1;
      cache.formEl.addEventListener( 'wpcf7mailsent', formSubmit);
    }
  };

  var showCursor = function(e) {
    var cursor = this.querySelector('.cursor');
    cursor.classList.add('active');
    window.addEventListener('mousemove', moveCursor);
    this.removeEventListener('mouseenter', showCursor);
    this.addEventListener('mouseleave', hideCursor);
    cursor.style.display = 'block';
    cursor.style.top = e.pageY + 'px';
    cursor.style.left = e.pageX + 'px';
    anime({
      targets: cursor,
      opacity: [0,1],
      duration: 300,
      easing: 'easeInOutQuad',
    });
  }

  var hideCursor = function() {
    this.addEventListener('mouseenter', showCursor);
    this.removeEventListener('mouseleave', hideCursor);
    window.removeEventListener('mousemove', moveCursor);
    var cursor = this.querySelector('.cursor');
    cursor.style.display = 'none';
    cursor.classList.remove('active');
  }

  var moveCursor = function(e) {
    var cursor = document.querySelector('.cursor.active');
    cursor.style.top = e.clientY + 'px';
    cursor.style.left = e.clientX + 'px';
    if(e.pageX > (window.innerWidth-cursor.offsetWidth)) {
      if(!cursor.classList.contains('reverse')) {
        cursor.classList.add('reverse');
      }
    }
    if(e.pageX < (window.innerWidth-cursor.offsetWidth)) {
      if(cursor.classList.contains('reverse')) {
        cursor.classList.remove('reverse');
      }
    }
  }



  var startForm = function() {
    anime({
      targets: cache.stepStarter,
      translateX: [0,-100],
      opacity: [1,0],
      duration: 500,
      easing: 'easeOutSine',
    });
    cache.form.style.display = "block";
    if (cache.formStep.length <= 1) {
      anime({
        targets: cache.nxtBtn,
        opacity: 0,
        duration: 400,
        easing: 'easeOutSine',
        complete: function() {
          cache.nxtBtn.style.display = "none";
          cache.submitBtn.style.display = "block";
          anime({
            targets: cache.submitBtn,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutSine',
          });
        }
      });
    }
    anime({
      targets: cache.form,
      translateX: [100, 0],
      opacity: [0,1],
      duration: 500,
      easing: 'easeOutSine',
    });
  }

  var nxtActive = function() {
    if(cache.nxtBtn.classList.contains('not-active')) {
      cache.nxtBtn.classList.remove('not-active');
      cache.formStep[index].classList.add('validated');
    }
  }

  var nxtStep = function() {
    var currentForm = cache.formStep[index];
    index++;
    var targetForm = cache.formStep[index];
    cache.form.querySelector('.current_step').innerHTML = index+1;

    if(!targetForm.classList.contains('validated')) {
      cache.nxtBtn.classList.add('not-active');
    }
    if(index == cache.formStep.length -1) {
      anime({
        targets: cache.nxtBtn,
        opacity: 0,
        duration: 400,
        easing: 'easeOutSine',
        complete: function() {
          cache.nxtBtn.style.display = "none";
          cache.submitBtn.style.display = "block";
          anime({
            targets: cache.submitBtn,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutSine',
          });
        }
      });
    }

    anime({
      targets: currentForm,
      translateX: [0,-100],
      opacity: [1,0],
      duration: 500,
      easing: 'easeOutSine',
      complete: function() {
        currentForm.style.display = "none";
      }
    });
    targetForm.style.display = "block";
    anime({
      targets: targetForm,
      translateX: [100, 0],
      opacity: [0,1],
      duration: 500,
      easing: 'easeOutSine',
    });
  }

  var formSubmit = function() {
    anime({
      targets: cache.formThanksImg,
      scale: [0.8, 1],
      opacity: [0,1],
      duration: 500,
      easing: 'easeOutSine',
    });

    anime({
      targets: cache.form,
      translateX: [0, -100],
      opacity: [1,0],
      duration: 500,
      easing: 'easeOutSine',
      complete: function() {
        cache.form.style.display = "none";
      }
    });
    cache.formThanks.style.display = "flex";
    anime({
      targets: cache.formThanks,
      translateX: [100, 0],
      opacity: [0,1],
      duration: 500,
      easing: 'easeOutSine',
    });

  }

  initFunc();
};
}
export { Concours }
