import anime from "../libs/anime";
import { enableScroll, disableScroll } from '../utils/utils';

class Home {
  constructor() {
    let cache = {};
    let self = this;

    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = function() {
      cache.bandeau = document.querySelectorAll(".bandeau");
      cache.concerts = document.querySelector(
        ".template-home .sec2 .concert_wrap"
      );
      cache.modalBtn = document.querySelector(".open_modal");
      cache.modal = document.querySelector(".modal_video");
      cache.modalBtnClose = document.querySelector(".modal_video .close");
    };

    let registerEvents = function() {
      if (cache.bandeau) {
        bandeau();
      }
      cache.modalBtn.addEventListener("click", openModal);
      cache.modalBtnClose.addEventListener("click", closeModal);
    };

    let openModal = function() {
      disableScroll();
      cache.modal.style.display = "block";

      anime({
        targets: cache.modal,
        opacity: [0, 1],
        duration: 400,
        easing: "easeOutQuad",
      });
    };

    let closeModal = function() {
      anime({
        targets: cache.modal,
        opacity: [1, 0],
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          enableScroll();
          cache.modal.style.display = "none";
          cache.modal
            .querySelector("iframe")
            .contentWindow.postMessage(
              '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
              "*"
            );
        },
      });
    };

    let bandeau = function() {
      for (let i = 0; i < cache.bandeau.length; i++) {
        let bandeau = cache.bandeau[i];
        anime({
          targets: bandeau,
          translateX: ["0%", "-100%"],
          duration: 10000,
          easing: "linear",
          loop: true,
        });
      }
    };

    initFunc();
  }
}
export { Home };
