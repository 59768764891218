import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class ArtisteArch {
  constructor() {
  let cache = {};
  let self = this;
  let scrollPos = 0;
  let scrollPrev = 0;
  let letterMobile = true;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    cache.scrollLinks = document.querySelectorAll('a.scroll');
    cache.letter = document.querySelector('.left .letter');
    cache.letterWrap = document.querySelectorAll('.letter_wrap');
    cache.letterWrap[0].classList.add('active');

    cache.searchInput = document.querySelector('#searchInput');
    cache.searchIcon = document.querySelector('.search-icon');
    cache.searchBar = document.querySelector('.search-bar');

    cache.letterFilter = document.querySelectorAll('.filter-alpha a.active');
    cache.filterTour = document.querySelector('.filter-tour .btn')
    cache.tour = document.querySelectorAll('.tour');
    cache.artists = document.querySelectorAll('.letter_wrap a');
    cache.mobileLetters = document.querySelectorAll('.letter_wrap .tablet');
  };

  var registerEvents = function() {
    for (var i = 0; i < cache.scrollLinks.length; i++) {
      cache.scrollLinks[i].addEventListener("click", scrollTo);
    }
    window.addEventListener('scroll', changeLetter);

    if(cache.tour) {
      bandeau();
    }
    cache.searchInput.addEventListener('keyup', logSearch);
    cache.filterTour.addEventListener("click", filterTour);
    cache.searchIcon.addEventListener("click", toggleSearch);
  };


  var scrollTo = function(e) {
    document.querySelector('body').classList.add('scroll');
    e.preventDefault();
    var target = document.querySelector(this.getAttribute("href"));
    if(!desktop.matches) {
      closeNav();
    }
    anime({
      targets: scrollElement,
      scrollTop: (scrollElement.scrollTop+target.getBoundingClientRect().top-200),
      duration: 1000,
      easing: 'easeOutQuad',
      complete: function () {
        document.querySelector('body').classList.remove('scroll');
        cache.letter.innerHTML = target.id;
      }
    });
  }

  var changeLetter = function() {
    scrollPrev = scrollPos;
    scrollPos = window.pageYOffset;
    for (var i = 0; i < cache.letterWrap.length; i++) {
      if(scrollPos > scrollPrev) {
        if(((scrollPos+window.innerHeight-300) > (cache.letterWrap[i].offsetTop)) && (scrollPos+window.innerHeight < (cache.letterWrap[i].offsetTop+cache.letterWrap[i].offsetHeight)) && (!cache.letterWrap[i].classList.contains('active'))){
          document.querySelector('.letter_wrap.active').classList.remove('active');
          cache.letterWrap[i].classList.add('active');
          cache.letter.innerHTML = cache.letterWrap[i].id;
        }
      }
      else if(scrollPrev > scrollPos) {
        if(((scrollPos+window.innerHeight-300) > (cache.letterWrap[i].offsetTop)) && (scrollPos+window.innerHeight/2 < (cache.letterWrap[i].offsetTop+cache.letterWrap[i].offsetHeight)) && (!cache.letterWrap[i].classList.contains('active'))){
          document.querySelector('.letter_wrap.active').classList.remove('active');
          cache.letterWrap[i].classList.add('active');
          cache.letter.innerHTML = cache.letterWrap[i].id;
        }
      }
    }
  }

  var bandeau = function() {
    for (var i = 0; i < cache.tour.length; i++) {
      var txt = document.querySelectorAll('.tour-bandeau .bandeau');
      for (var ii = 0; ii < txt.length; ii++) {
        anime({
          targets: txt[ii],
          translateX: ['0%', '-100%'],
          duration: 6000,
          easing: 'linear',
          loop: true,
        });
      }
    }
  }

  var filterTour = function() {
    if(!this.classList.contains('active')) {
      this.classList.add('active');
      for (var i = 0; i < cache.artists.length; i++) {
        if(!cache.artists[i].classList.contains('tour')) {
          cache.artists[i].style.display = 'none';
        }
      }
      for (var i = 0; i < cache.letterWrap.length; i++) {
        if(!cache.letterWrap[i].querySelector('.tour')){
          cache.letterFilter[i].classList.remove('active');
          cache.letterFilter[i].classList.add('not-active');
        }
      }
    }
    else {
      this.classList.remove('active');
      for (var i = 0; i < cache.artists.length; i++) {
        cache.artists[i].style.display = 'block';
      }
      for (var i = 0; i < cache.letterWrap.length; i++) {
        if(!cache.letterWrap[i].querySelector('.tour')){
          cache.letterFilter[i].classList.add('active');
          cache.letterFilter[i].classList.remove('not-active');
        }
      }
    }

  }

  var toggleSearch = function(){
    if(!this.classList.contains("active")) {
      this.classList.add("active");
      cache.searchBar.style.display = "block";
      anime({
        targets: cache.searchBar,
        translateX: ['-5%','0%'],
        opacity: [0,1],
        duration: 300,
        easing: 'easeOutQuad',
      });
    }
    else {
      this.classList.remove("active");
      cache.searchInput.value = "";
      for (var i = 0; i < cache.artists.length; i++) {
        cache.artists[i].style.display = 'block';
      }
      anime({
        targets: cache.searchBar,
        translateX: ['0%', '-5%'],
        opacity: [1,0],
        duration: 300,
        easing: 'easeOutQuad',
        complete: function() {
          cache.searchBar.style.display = "none";
        }
      });
    }
  }

  var logSearch = function() {
    var filter = cache.searchInput.value.toUpperCase();
    if(tablet.matches && letterMobile && filter) {
      letterMobile = false;
      for (var i = 0; i < cache.mobileLetters.length; i++) {
        cache.mobileLetters[i].style.display = "none";
      }
    }
    if(tablet.matches && !letterMobile && !filter) {
      letterMobile = true;
      for (var i = 0; i < cache.mobileLetters.length; i++) {
        cache.mobileLetters[i].style.display = "block";
      }
    }
    for (let i = 0; i < cache.artists.length; i++) {
      let a = cache.artists[i].getElementsByTagName("h4")[0];
      let txtValue = a.textContent || a.innerText;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        cache.artists[i].style.display = "";
      } else {
        cache.artists[i].style.display = "none";
      }
    }
  }


  initFunc();
};
}
export { ArtisteArch }
