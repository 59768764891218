import anime from "../libs/anime";
import { HoverImg } from "./HoverImg";
import { Font } from "./Font";

class ConcertLanding {
  constructor() {
    let cache = {};
    let self = this;
    let ppp = 6;
    let vConcerts = 0;
    let total = parseInt(
      document.querySelector(".template-concerts.concert-page .sec2").dataset
        .count
    );
    let filterArt = false;
    let filterRegion = false;
    let filterDate = false;
    let date = [];
    let artist = [];
    let salle = [];
    let str;
    let filterParent;
    const desktop = window.matchMedia("(min-width: 1300px)");

    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = function() {
      cache.wrap = document.querySelector(".wrap");
      cache.toggle_concert = document.querySelector(".toggle_concert");
      cache.concert_wrap = document.querySelector(
        ".template-concerts.concert-page"
      );
      cache.more = document.querySelector(
        ".template-concerts.concert-page .sec2 .more"
      );
      cache.concerts = document.querySelector(
        ".template-concerts.concert-page .sec2 .concert_wrap"
      );
      cache.filterWrap = document.querySelector(
        ".template-concerts.concert-page .sec1 .filter"
      );
      cache.select = cache.filterWrap.querySelectorAll(".select-parent");
      cache.searchDate = document.querySelector(
        ".template-concerts.concert-page .sec1 .filter .searchDate"
      );
      cache.searchArtist = document.querySelector(
        ".template-concerts.concert-page .sec1 .filter .searchArtist"
      );
      cache.searchRegion = document.querySelector(
        ".template-concerts.concert-page .sec1 .filter .searchRegion"
      );
      cache.dateFilter = cache.filterWrap.querySelectorAll(
        ".template-concerts.concert-page .filter-date li"
      );
      cache.artistsFilter = cache.filterWrap.querySelectorAll(
        ".template-concerts.concert-page .filter-artist li"
      );
      cache.regionsFilter = cache.filterWrap.querySelectorAll(
        ".template-concerts.concert-page .filter-region li"
      );
      cache.noResult = document.querySelector(".no-result");

      cache.resetBtn = cache.filterWrap.querySelectorAll(".reset");

      cache.mobileValider = cache.concert_wrap.querySelector(".filterBottom");
      cache.mobileCloseFilter = cache.concert_wrap.querySelector(
        ".filterTop span"
      );
      cache.mobileOpenFilter = cache.concert_wrap.querySelector(".open_filter");
    };

    const registerEvents = function() {
      cache.more.addEventListener("click", load_posts);
      cache.searchDate.addEventListener("keyup", logSearchDate);
      cache.searchArtist.addEventListener("keyup", logSearchArtist);
      cache.searchRegion.addEventListener("keyup", logSearchRegion);
      for (let i = 0; i < cache.select.length; i++) {
        cache.select[i].addEventListener("click", open_select);
      }
      for (let i = 0; i < cache.dateFilter.length; i++) {
        cache.dateFilter[i].addEventListener("click", load_Date);
      }
      for (let i = 0; i < cache.artistsFilter.length; i++) {
        cache.artistsFilter[i].addEventListener("click", load_Artists);
      }
      for (let i = 0; i < cache.regionsFilter.length; i++) {
        cache.regionsFilter[i].addEventListener("click", load_Regions);
      }
      for (let i = 0; i < cache.resetBtn.length; i++) {
        cache.resetBtn[i].addEventListener("click", reset);
      }
      cache.mobileOpenFilter.addEventListener("click", open_filter_mobile);
      cache.mobileValider.addEventListener("click", close_filter_mobile);
      cache.mobileCloseFilter.addEventListener("click", close_filter_mobile);
    };

    const reset = function() {
      let filterA = document.querySelectorAll(
        ".template-concerts.concert-page .sec1 .filter .active"
      );
      let filterNA = document.querySelectorAll(
        ".template-concerts.concert-page .sec1 .filter .not-active"
      );
      let filterSelectA = document.querySelectorAll(
        ".template-concerts.concert-page .sec1 .filter .active--filter"
      );

      for (let i = 0; i < filterA.length; i++) {
        filterA[i].classList.remove("active");
      }
      for (let i = 0; i < filterNA.length; i++) {
        filterNA[i].classList.remove("not-active");
      }
      for (let i = 0; i < filterSelectA.length; i++) {
        filterSelectA[i].classList.remove("active--filter");
      }

      cache.more.style.pointerEvents = "all";
      anime({
        targets: cache.more,
        opacity: [1],
        duration: 400,
        easing: "easeOutSine",
      });
      ppp = 48;

      filterArt = false;
      filterRegion = false;
      filterDate = false;

      const concerts = cache.concerts.querySelectorAll(".container-month");
      anime({
        targets: concerts,
        opacity: [1, 0],
        translateX: ["0%", "-70%"],
        duration: 300,
        delay: anime.stagger(150),
        easing: "easeOutSine",
        complete: function() {
          for (let i = 0; i < concerts.length; i++) {
            concerts[i].parentNode.removeChild(concerts[i]);
          }
          setTimeout(function() {
            load();
          }, 100);
        },
      });
    };

    const assignContainerClasses = function() {
      const containers = document.querySelectorAll(".container-month");
      containers.forEach((container) =>
        container.classList.remove("first", "last")
      );

      if (containers.length > 0) {
        containers[0].classList.add("first");
        if (containers.length > 1) {
          containers[containers.length - 1].classList.add("last");
        }
      }
    };

    const open_filter_mobile = function() {
      cache.filterWrap.style.display = "block";
      anime({
        targets: cache.filterWrap,
        opacity: [0, 1],
        translateX: [-50, 0],
        duration: 400,
        easing: "easeOutSine",
      });
      anime({
        targets: cache.toggle_concert,
        opacity: [1, 0],
        translateY: [0, -50],
        duration: 400,
        easing: "easeOutSine",
        complete: function() {
          if (cache.toggle_concert) {
            cache.toggle_concert.style.display = "none";
          }
        },
      });
    };
    const close_filter_mobile = function() {
      anime({
        targets: cache.filterWrap,
        opacity: [1, 0],
        translateX: [0, -50],
        duration: 400,
        easing: "easeOutSine",
        complete: function() {
          cache.filterWrap.style.display = "none";
          close_select();
        },
      });
      if (cache.toggle_concert) {
        cache.toggle_concert.style.display = "block";
        anime({
          targets: cache.toggle_concert,
          opacity: [0, 1],
          translateY: [-50, 0],
          duration: 400,
          easing: "easeOutSine",
        });
      }
    };

    const open_select = function() {
      const drop = this.parentNode.querySelector(".dropdown");
      if (desktop.matches) {
        document
          .querySelector(".template-concerts.concert-page .sec1")
          .addEventListener("mouseleave", close_select);
      } else {
        drop
          .querySelector(".filterTop")
          .addEventListener("click", close_select);
      }
      if (!this.classList.contains("active")) {
        const active = cache.filterWrap.querySelector(".select-parent.active");
        if (active) {
          active.classList.remove("active");
          anime({
            targets: active.parentNode.querySelector(".dropdown"),
            opacity: 0,
            height: 0,
            duration: 400,
            easing: "easeOutSine",
            complete: function() {
              active.parentNode.querySelector(".dropdown").style.display =
                "none";
            },
          });
        }
        this.classList.add("active");
        drop.style.display = "block";
        drop.style.height = "auto";
        let height = drop.offsetHeight;
        drop.style.height = 0;

        anime({
          targets: drop,
          opacity: [0, 1],
          height: [0, height],
          duration: 400,
          easing: "easeOutSine",
        });
      } else {
        this.classList.remove("active");
        anime({
          targets: drop,
          opacity: 0,
          height: 0,
          duration: 400,
          easing: "easeOutSine",
          complete: function() {
            drop.style.display = "none";
          },
        });
      }
    };
    const close_select = function() {
      document
        .querySelector(".template-concerts.concert-page .sec1")
        .removeEventListener("mouseleave", close_select);
      const active = cache.filterWrap.querySelector(".select-parent.active");
      if (active) {
        active.classList.remove("active");
        anime({
          targets: active.parentNode.querySelector(".dropdown"),
          opacity: 0,
          height: 0,
          duration: 400,
          easing: "easeOutSine",
          complete: function() {
            active.parentNode.querySelector(".dropdown").style.display = "none";
          },
        });
      }
    };

    // Function to have sticky filters on mobile
    let lastScrollTop = 0;
    const sec1 = document.querySelector('.template-concerts .sec1');

    window.addEventListener('scroll', function() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        sec1.classList.add('hidden');
      } else {
        sec1.classList.remove('hidden');
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });

    const logSearchDate = function() {
      let filter = cache.searchDate.value.toUpperCase();
      for (i = 0; i < cache.dateFilter.length; i++) {
        a = cache.dateFilter[i];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          cache.dateFilter[i].style.display = "";
        } else {
          cache.dateFilter[i].style.display = "none";
        }
      }
    };
    const logSearchArtist = function() {
      let filter = cache.searchArtist.value.toUpperCase();
      for (i = 0; i < cache.artistsFilter.length; i++) {
        a = cache.artistsFilter[i];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          cache.artistsFilter[i].style.display = "";
        } else {
          cache.artistsFilter[i].style.display = "none";
        }
      }
    };
    const logSearchRegion = function() {
      let filter = cache.searchRegion.value.toUpperCase();
      for (i = 0; i < cache.regionsFilter.length; i++) {
        a = cache.regionsFilter[i];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          cache.regionsFilter[i].style.display = "";
        } else {
          cache.regionsFilter[i].style.display = "none";
        }
      }
    };

    const load_Date = function() {
      if (desktop.matches) {
        cache.filterWrap.style.pointerEvents = "none";
        anime({
          targets: cache.filterWrap,
          opacity: [0.4],
          duration: 400,
          easing: "easeOutSine",
        });
      } else {
        filterParent = this.parentNode;
        filterParent.style.pointerEvents = "none";
        anime({
          targets: filterParent,
          opacity: [0.4],
          duration: 400,
          easing: "easeOutSine",
        });
      }
      if (this.classList.contains("active")) {
        this.classList.remove("active");
        this.classList.add("not-active");
        const dateArray = document.querySelectorAll(
          ".template-concerts.concert-page .filter-date li.active"
        );
        if (dateArray.length == 0) {
          for (let i = 0; i < cache.dateFilter.length; i++) {
            cache.dateFilter[i].classList.remove("not-active");
          }
          if (!filterArt && !filterRegion) {
            cache.more.style.pointerEvents = "all";
            anime({
              targets: cache.more,
              opacity: [1],
              duration: 400,
              easing: "easeOutSine",
            });
            ppp = 6;
          } else {
            cache.more.style.pointerEvents = "none";
            anime({
              targets: cache.more,
              opacity: [1, 0],
              duration: 400,
              easing: "easeOutSine",
            });
            ppp = -1;
          }
          filterDate = false;
          cache.filterWrap
            .querySelector(".filter-date .select-parent")
            .classList.remove("active--filter");
        } else {
          cache.more.style.pointerEvents = "none";
          anime({
            targets: cache.more,
            opacity: [1, 0],
            duration: 400,
            easing: "easeOutSine",
          });
          ppp = -1;
        }
      } else {
        const dateActive = document.querySelector(
          ".template-concerts.concert-page .filter-date li.active"
        );
        if (dateActive) {
          dateActive.classList.remove("active");
        }
        this.classList.add("active");
        this.classList.remove("not-active");
        cache.more.style.pointerEvents = "none";
        anime({
          targets: cache.more,
          opacity: [1, 0],
          duration: 400,
          easing: "easeOutSine",
        });
        ppp = -1;
        for (let i = 0; i < cache.dateFilter.length; i++) {
          if (!cache.dateFilter[i].classList.contains("active")) {
            cache.dateFilter[i].classList.add("not-active");
          }
        }
        filterDate = true;
        cache.filterWrap
          .querySelector(".filter-date .select-parent")
          .classList.add("active--filter");
      }

      vConcerts = 0;
      date = [];
      date.push(this.dataset.year + this.dataset.month + "01");
      date.push(this.dataset.year + this.dataset.month + "31");

      if (!cache.concerts.classList.contains(".filtered")) {
        let concerts = cache.concerts.querySelectorAll(".container-month");
        if (concerts.length > 0) {
          for (let i = 0; i < concerts.length; i++) {
            if (i == concerts.length - 1) {
              anime({
                targets: concerts[i],
                opacity: [1, 0],
                translateX: ["0%", "-70%"],
                duration: 300,
                delay: 150 * i,
                easing: "easeOutSine",
                complete: function() {
                  for (let ii = 0; ii < concerts.length; ii++) {
                    concerts[ii].parentNode.removeChild(concerts[ii]);
                  }
                  setTimeout(function() {
                    load();
                  }, 100);
                },
              });
            } else {
              anime({
                targets: concerts[i],
                opacity: [1, 0],
                translateX: ["0%", "-70%"],
                duration: 500,
                delay: 150 * i,
                easing: "easeOutSine",
              });
            }
          }
        } else {
          load();
        }
      }
    };

    const load_Regions = function() {
      if (desktop.matches) {
        cache.filterWrap.style.pointerEvents = "none";
        anime({
          targets: cache.filterWrap,
          opacity: [0.4],
          duration: 400,
          easing: "easeOutSine",
        });
      } else {
        filterParent = this.parentNode;
        filterParent.style.pointerEvents = "none";
        anime({
          targets: filterParent,
          opacity: [0.4],
          duration: 400,
          easing: "easeOutSine",
        });
      }

      let regionArray = document.querySelectorAll(
        ".template-concerts.concert-page .filter-region li.active"
      );

      if (this.classList.contains("active")) {
        this.classList.remove("active");
        this.classList.add("not-active");
        regionArray = document.querySelectorAll(
          ".template-concerts.concert-page .filter-region li.active"
        );
        cache.filterWrap.querySelector(
          ".filter-region .select-parent .nb"
        ).innerHTML = regionArray.length;
        if (regionArray.length == 0) {
          for (let i = 0; i < cache.regionsFilter.length; i++) {
            cache.regionsFilter[i].classList.remove("not-active");
          }
          if (!filterArt && !filterDate) {
            cache.more.style.pointerEvents = "all";
            anime({
              targets: cache.more,
              opacity: [1],
              duration: 400,
              easing: "easeOutSine",
            });
            ppp = 6;
          } else {
            cache.more.style.pointerEvents = "none";
            anime({
              targets: cache.more,
              opacity: [1, 0],
              duration: 400,
              easing: "easeOutSine",
            });
            ppp = -1;
          }
          filterRegion = false;
          cache.filterWrap
            .querySelector(".filter-region .select-parent")
            .classList.remove("active--filter");
        } else {
          cache.more.style.pointerEvents = "none";
          anime({
            targets: cache.more,
            opacity: [1, 0],
            duration: 400,
            easing: "easeOutSine",
          });
          ppp = -1;
        }
      } else {
        this.classList.add("active");
        this.classList.remove("not-active");
        regionArray = document.querySelectorAll(
          ".template-concerts.concert-page .filter-region li.active"
        );
        cache.filterWrap.querySelector(
          ".filter-region .select-parent .nb"
        ).innerHTML = regionArray.length;
        cache.more.style.pointerEvents = "none";
        anime({
          targets: cache.more,
          opacity: [1, 0],
          duration: 400,
          easing: "easeOutSine",
        });
        ppp = -1;
        if (regionArray.length == 1) {
          for (let i = 0; i < cache.regionsFilter.length; i++) {
            if (!cache.regionsFilter[i].classList.contains("active")) {
              cache.regionsFilter[i].classList.add("not-active");
            }
          }
          filterRegion = true;
          cache.filterWrap
            .querySelector(".filter-region .select-parent")
            .classList.add("active--filter");
        }
      }

      vConcerts = 0;
      salle = [];
      for (let i = 0; i < regionArray.length; i++) {
        if (regionArray[i].dataset.salleid) {
          let salleArray = regionArray[i].dataset.salleid.split(",");
          for (let ii = 0; ii < salleArray.length; ii++) {
            salle.push(parseInt(salleArray[ii]));
          }
        }
      }

      if (!cache.concerts.classList.contains(".filtered")) {
        let concerts = cache.concerts.querySelectorAll(".container-month");
        if (concerts.length > 0) {
          for (let i = 0; i < concerts.length; i++) {
            if (i == concerts.length - 1) {
              anime({
                targets: concerts[i],
                opacity: [1, 0],
                translateX: ["0%", "-70%"],
                duration: 300,
                delay: 150 * i,
                easing: "easeOutSine",
                complete: function() {
                  for (let ii = 0; ii < concerts.length; ii++) {
                    concerts[ii].parentNode.removeChild(concerts[ii]);
                  }
                  setTimeout(function() {
                    load();
                  }, 100);
                },
              });
            } else {
              anime({
                targets: concerts[i],
                opacity: [1, 0],
                translateX: ["0%", "-70%"],
                duration: 500,
                delay: 150 * i,
                easing: "easeOutSine",
              });
            }
          }
        } else {
          load();
        }
      }
    };

    const load_Artists = function() {
      if (desktop.matches) {
        cache.filterWrap.style.pointerEvents = "none";
        anime({
          targets: cache.filterWrap,
          opacity: [0.4],
          duration: 400,
          easing: "easeOutSine",
        });
      } else {
        filterParent = this.parentNode;
        filterParent.style.pointerEvents = "none";
        anime({
          targets: filterParent,
          opacity: [0.4],
          duration: 400,
          easing: "easeOutSine",
        });
      }

      let artistArray = document.querySelectorAll(
        ".template-concerts.concert-page .filter-artist li.active"
      );

      if (this.classList.contains("active")) {
        this.classList.remove("active");
        this.classList.add("not-active");
        artistArray = document.querySelectorAll(
          ".template-concerts.concert-page .filter-artist li.active"
        );
        cache.filterWrap.querySelector(
          ".filter-artist .select-parent .nb"
        ).innerHTML = artistArray.length;
        if (artistArray.length == 0) {
          for (let i = 0; i < cache.artistsFilter.length; i++) {
            cache.artistsFilter[i].classList.remove("not-active");
          }
          if (!filterRegion && !filterDate) {
            cache.more.style.pointerEvents = "all";
            anime({
              targets: cache.more,
              opacity: [1],
              duration: 400,
              easing: "easeOutSine",
            });
            ppp = 6;
          } else {
            cache.more.style.pointerEvents = "none";
            anime({
              targets: cache.more,
              opacity: [1, 0],
              duration: 400,
              easing: "easeOutSine",
            });
            ppp = -1;
          }
          filterArt = false;
          cache.filterWrap
            .querySelector(".filter-artist .select-parent")
            .classList.remove("active--filter");
        } else {
          cache.more.style.pointerEvents = "none";
          anime({
            targets: cache.more,
            opacity: [1, 0],
            duration: 400,
            easing: "easeOutSine",
          });
          ppp = -1;
        }
      } else {
        this.classList.add("active");
        this.classList.remove("not-active");
        artistArray = document.querySelectorAll(
          ".template-concerts.concert-page .filter-artist li.active"
        );
        cache.filterWrap.querySelector(
          ".filter-artist .select-parent .nb"
        ).innerHTML = artistArray.length;
        cache.more.style.pointerEvents = "none";
        anime({
          targets: cache.more,
          opacity: [1, 0],
          duration: 400,
          easing: "easeOutSine",
        });
        ppp = -1;
        if (artistArray.length == 1) {
          for (let i = 0; i < cache.artistsFilter.length; i++) {
            if (!cache.artistsFilter[i].classList.contains("active")) {
              cache.artistsFilter[i].classList.add("not-active");
            }
          }
          filterArt = true;
          cache.filterWrap
            .querySelector(".filter-artist .select-parent")
            .classList.add("active--filter");
        }
      }

      vConcerts = 0;
      artist = [];
      for (let i = 0; i < artistArray.length; i++) {
        if (artistArray[i].dataset.artistid) {
          let id = parseInt(artistArray[i].dataset.artistid);
          artist.push(id);
        }
      }

      if (!cache.concerts.classList.contains(".filtered")) {
        let concerts = cache.concerts.querySelectorAll(".container-month");
        if (concerts.length > 0) {
          for (let i = 0; i < concerts.length; i++) {
            if (i == concerts.length - 1) {
              anime({
                targets: concerts[i],
                opacity: [1, 0],
                translateX: ["0%", "-70%"],
                duration: 300,
                delay: 150 * i,
                easing: "easeOutSine",
                complete: function() {
                  for (let ii = 0; ii < concerts.length; ii++) {
                    concerts[ii].parentNode.removeChild(concerts[ii]);
                  }
                  setTimeout(function() {
                    load();
                  }, 100);
                },
              });
            } else {
              anime({
                targets: concerts[i],
                opacity: [1, 0],
                translateX: ["0%", "-70%"],
                duration: 500,
                delay: 150 * i,
                easing: "easeOutSine",
              });
            }
          }
        } else {
          load();
        }
      }
    };

    // Utilisation pour les filtres
    const load = function() {
      let str = "&vConcerts=" + vConcerts + "&ppp=" + ppp;
      if (filterDate) {
        str += "&date1=" + date[0] + "&date2=" + date[1];
      }
      if (filterArt) {
        str += "&artist=" + artist;
      }
      if (filterRegion) {
        str += "&salle=" + salle;
      }

      if (filterDate || filterArt || filterRegion) {
        for (let i = 0; i < cache.resetBtn.length; i++) {
          cache.resetBtn[i].classList.add("active");
        }
      }
      if (!filterDate && !filterArt && !filterRegion) {
        for (let i = 0; i < cache.resetBtn.length; i++) {
          cache.resetBtn[i].classList.remove("active");
        }
      }

      load_concerts(str);
    };

    // Utilisation pour charger plus de concerts
    const load_posts = function() {
      const lastConcert = document.querySelector(
        ".template-concerts.concert-page .sec2 .last .concert:last-child"
      );
      let lastMonth = "";
      if (lastConcert) {
        lastMonth = lastConcert.dataset.month;
      }

      let vConcerts = 0;
      let ppp = -1;
      let queryStr = "";

      if (lastMonth) {
        const [month, year] = lastMonth.split(" ");
        const lastMonthDate = new Date(`${year}-${month}-01`);
        lastMonthDate.setMonth(lastMonthDate.getMonth() + 1);

        // Début de la période : Mois suivant après le dernier mois chargé
        const startMonth = (lastMonthDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const startYear = lastMonthDate.getFullYear();

        // Fin de la période : Trois mois après le dernier mois chargé
        lastMonthDate.setMonth(lastMonthDate.getMonth() + 2);
        const endMonth = (lastMonthDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const endYear = lastMonthDate.getFullYear();

        queryStr = `&date1=${startYear}${startMonth}01&date2=${endYear}${endMonth}31&vConcerts=${vConcerts}&ppp=${ppp}`;
      } else {
        queryStr = `&vConcerts=${vConcerts}&ppp=${ppp}`;
      }

      load_concerts(queryStr);
    };

    const load_concerts = function(queryStr) {
      const colorArray = ["mauve", "blue", "green", "yellow", "red"];
      let colorIndex = 0;

      const truncateMonth = function(month) {
        return month.length > 3 ? month.slice(0, 3) : month;
      };

      jQuery.ajax({
        type: "GET",
        dataType: "html",
        url: ajaxurl,
        data: queryStr + "&action=more_concert_ajax",
        success: function(response) {
          let data = jQuery(response);
          if (data.length) {
            cache.noResult.style.display = "none";
            let currentMonth = "";
            let containerMonth;

            for (let i = 0; i < data.length; i++) {
              let month = data[i].dataset.month.split(" ")[0];
              let year = data[i].dataset.month.split(" ")[1];
              let truncatedMonth = truncateMonth(month);

              if (month + year !== currentMonth) {
                currentMonth = month + year;

                containerMonth = document.createElement("div");
                containerMonth.classList.add("container-month");

                let monthDiv = document.createElement("div");
                monthDiv.classList.add("month");
                let monthSpan = document.createElement("span");
                monthSpan.classList.add(`txt_${colorArray[colorIndex]}`);
                monthSpan.innerHTML = truncatedMonth;
                let yearSpan = document.createElement("span");
                yearSpan.classList.add("year");
                yearSpan.classList.add(`txt_${colorArray[colorIndex]}`);
                yearSpan.innerHTML = year;

                colorIndex = (colorIndex + 1) % colorArray.length;

                monthDiv.append(monthSpan);
                monthDiv.append(yearSpan);

                containerMonth.append(monthDiv);

                var wrapperConcert = document.createElement("div");
                wrapperConcert.classList.add("wrapper-concert");
                containerMonth.append(wrapperConcert);

                cache.concerts.append(containerMonth);
              }

              wrapperConcert.append(data[i]);
            }

            let hoverImg = new HoverImg();
            if (desktop.matches) {
              cache.filterWrap.style.pointerEvents = "all";
              anime({
                targets: cache.filterWrap,
                opacity: 1,
                duration: 400,
                easing: "easeOutSine",
              });
            } else {
              if (filterParent) {
                filterParent.style.pointerEvents = "all";
              }
              anime({
                targets: filterParent,
                opacity: 1,
                duration: 400,
                easing: "easeOutSine",
              });
            }

            anime({
              targets: cache.concerts.querySelectorAll(".container-month"),
              opacity: [0, 1],
              translateX: ["-70%", "0%"],
              duration: 700,
              delay: function(el, i) {
                return 150 * i;
              },
              easing: "easeOutSine",
            });

            assignContainerClasses();
          } else {
            cache.noResult.style.display = "block";
            if (desktop.matches) {
              cache.filterWrap.style.pointerEvents = "all";
              anime({
                targets: cache.filterWrap,
                opacity: 1,
                duration: 400,
                easing: "easeOutSine",
              });
            } else {
              filterParent.style.pointerEvents = "all";
              anime({
                targets: filterParent,
                opacity: 1,
                duration: 400,
                easing: "easeOutSine",
              });
            }
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          if (desktop.matches) {
            cache.filterWrap.style.pointerEvents = "all";
            anime({
              targets: cache.filterWrap,
              opacity: 1,
              duration: 400,
              easing: "easeOutSine",
            });
          } else {
            filterParent.style.pointerEvents = "all";
            anime({
              targets: filterParent,
              opacity: 1,
              duration: 400,
              easing: "easeOutSine",
            });
          }
        },
      });
      return false;
    };
    initFunc();
  }
}
export { ConcertLanding };
