import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class SliderVideo {
  constructor() {
  let cache = {};
  let self = this;
  let galleryAvailScroll = 0;
  let handlerAvailScroll = 0;
  let handlerLeft = 0;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    cache.container = document.querySelector('.slider_video-inner');
    cache.containerSlide = cache.container.querySelector('.slide');
    cache.arrow_left = document.querySelector('.slider_video-nav .arrow_left');
    cache.arrow_right = document.querySelector('.slider_video-nav .arrow_right');
  };

  var registerEvents = function() {
    cache.arrow_left.addEventListener("click", scrollLeft);
    cache.arrow_right.addEventListener("click", scrollRight);
  }

  var scrollLeft = function() {
    anime({
      targets: cache.container,
      scrollLeft: cache.container.scrollLeft-cache.containerSlide.offsetWidth,
      duration: 400,
      easing: 'easeOutQuad',
    });
  }

  var scrollRight = function() {
    anime({
      targets: cache.container,
      scrollLeft: cache.container.scrollLeft+cache.containerSlide.offsetWidth,
      duration: 400,
      easing: 'easeOutQuad',
    });
  }

  initFunc();
};
}
export { SliderVideo }
