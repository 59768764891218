import utils from "../utils/utils";
import anime from "../libs/anime";
import Hammer from "../libs/hammer";
import globalVar from "../utils/globalVar";

class HoverImg {
  constructor() {
    let desktop = window.matchMedia("(min-width: 991px)");
    let cache = {};
    let self = this;
    let init, mouseX, mouseY, positionElement, printout, timer;
    let img;
    const tablet = window.matchMedia("(max-width: 991px)");
    const scrollElement =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement;

    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = function() {
      cache.cursor = document.querySelector(".hover_img-cursor");
      cache.hoverImg = document.querySelectorAll(".hover_img");
    };

    let registerEvents = function() {
      if (desktop.matches) {
        for (let i = 0; i < cache.hoverImg.length; i++) {
          cache.hoverImg[i].addEventListener("mouseover", showImage);
        }
      }
    };

    let showImage = function(e) {
      window.addEventListener("mousemove", moveImage);
      this.removeEventListener("mouseover", showImage);
      this.addEventListener("mouseleave", hideImage);
      img = this.dataset.image;
      cache.cursor.style.backgroundImage = "url('" + img + "')";
      cache.cursor.style.display = "block";
      cache.cursor.style.top = e.pageY + "px";
      cache.cursor.style.left = e.pageX + "px";
      anime({
        targets: cache.cursor,
        opacity: [0, 1],
        duration: 300,
        easing: "easeInOutQuad",
      });
    };

    let moveImage = function(e) {
      cache.cursor.style.top = e.clientY + "px";
      cache.cursor.style.left = e.clientX + "px";

      if (e.clientY > window.innerHeight - cache.cursor.offsetHeight / 1.5) {
        if (!cache.cursor.classList.contains("reverseH")) {
          cache.cursor.classList.add("reverseH");
        }
      }
      if (e.clientY < window.innerHeight - cache.cursor.offsetHeight / 1.5) {
        if (cache.cursor.classList.contains("reverseH")) {
          cache.cursor.classList.remove("reverseH");
        }
      }
    };

    let hideImage = function() {
      this.addEventListener("mouseover", showImage);
      this.removeEventListener("mouseleave", hideImage);
      window.removeEventListener("mousemove", moveImage);
      cache.cursor.style.display = "none";
    };

    initFunc();
  }
}
export { HoverImg };
