import { enableScroll, disableScroll } from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Artiste {
  constructor() {
  let cache = {};
  let self = this;
  let scrollPos = 0;
  let scrollPrev = 0;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    cache.bandeau = document.querySelectorAll('.bandeau');
    cache.scrollLinks = document.querySelectorAll('.btn.scroll');
    cache.slide = document.querySelectorAll('.video_wrap .slide');
    cache.modal = document.querySelector('.modal_video');
    cache.modalVideos = document.querySelectorAll('.modal_video .video');
    cache.modalBtnClose = document.querySelector('.modal_video .close');
    cache.sec4 = document.querySelector('.sec4');
    cache.cursor = document.querySelector('.cursor');
    cache.cursorSpan = cache.cursor.querySelectorAll('.cursor .wrap_cursor span');

    cache.review = document.querySelector('.review');
    cache.reviewNav = document.querySelectorAll('.review_nav-sources .source, .review_nav .bullet');
    cache.reviewNavS = document.querySelectorAll('.review_nav-sources .source');
    cache.reviewNavB = document.querySelectorAll('.review_nav .bullet');
    cache.reviewContent = document.querySelector('.review_content');
    cache.reviewTab = document.querySelectorAll('.review_tab');
    if(cache.reviewContent) {
      cache.reviewContent.style.height = cache.reviewTab[0].offsetHeight+"px";
    }
  };

  var registerEvents = function() {
    if(cache.bandeau.length > 0) {
      bandeau();
    }
    if(cache.slide.length > 0) {
      for (var i = 0; i < cache.slide.length; i++) {
        cache.slide[i].addEventListener("click", openModal);[i]
      }
    }
    cache.sec4.addEventListener('mouseenter', showCursor);
    for (var i = 0; i < cache.scrollLinks.length; i++) {
      cache.scrollLinks[i].addEventListener("click", scrollTo);
    }
    if(cache.modal) {
      cache.modalBtnClose.addEventListener('click', closeModal);
    }
    for (var i = 0; i < cache.cursorSpan.length; i++) {
      anime({
        targets: cache.cursorSpan,
        translateX: ['0%', '-118%'],
        duration: 4000,
        easing: 'linear',
        loop: true,
      });
    }

    if(cache.reviewContent) {
      for (var i = 0; i < cache.reviewNav.length; i++) {
        cache.reviewNav[i].addEventListener("click", reviewSlide);
      }
    }
  };


  var scrollTo = function(e) {
    document.querySelector('body').classList.add('scroll');
    e.preventDefault();
    var target = document.querySelector(this.getAttribute("href"));
    anime({
      targets: scrollElement,
      scrollTop: (scrollElement.scrollTop+target.getBoundingClientRect().top-100),
      duration: 1000,
      easing: 'easeOutQuad',
      complete: function () {
        document.querySelector('body').classList.remove('scroll');
      }
    });
  }

  var openModal = function() {
    disableScroll();
    cache.modal.style.display = "block";
    cache.modalVideos[this.dataset.index].classList.add('active');

    anime({
      targets: cache.modal,
      opacity: [0,1],
      duration: 400,
      easing: 'easeOutQuad'
    });
  }

  var closeModal = function() {
    anime({
      targets: cache.modal,
      opacity: [1,0],
      duration: 400,
      easing: 'easeOutQuad',
      complete: function() {
        enableScroll();
        cache.modal.style.display = "none";
        var video = cache.modal.querySelector('.video.active');
	      video.querySelector('iframe').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        video.classList.remove('active');
      }
    });

  }

  var reviewSlide = function() {
    if(!this.classList.contains('active')) {
      var i =  this.dataset.index;
      cache.review.querySelector('.review_nav-sources .source.active').classList.remove('active');
      cache.review.querySelector('.review_nav .bullet.active').classList.remove('active');
      cache.reviewNavS[i].classList.add('active');
      cache.reviewNavB[i].classList.add('active');
      anime({
        targets: cache.reviewContent.querySelector('.review_tab.active'),
        opacity: [1,0],
        translateX: [0,50],
        duration: 400,
        easing: 'easeOutQuad'
      });
      cache.reviewContent.querySelector('.review_tab.active').classList.remove('active');
      cache.reviewTab[i].classList.add('active');
      anime({
        targets: cache.reviewTab[i],
        opacity: [0,1],
        translateX: [-50, 0],
        duration: 400,
        easing: 'easeOutQuad'
      });

      anime({
        targets: cache.reviewContent,
        height: cache.reviewTab[i].offsetHeight,
        duration: 400,
        easing: 'easeOutQuad'
      });
    }
  }

  var bandeau = function() {
    for (var i = 0; i < cache.bandeau.length; i++) {
      var bandeau = cache.bandeau[i];
      anime({
        targets: bandeau,
        translateX: ['0%', '-100%'],
        duration: 3000,
        easing: 'linear',
        loop: true,
      });
    }
  }

  var logSearch = function() {
    var filter = cache.searchInput.value.toUpperCase();
    for (i = 0; i < cache.artists.length; i++) {
      a = cache.artists[i].getElementsByTagName("h4")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        cache.artists[i].style.display = "";
      } else {
        cache.artists[i].style.display = "none";
      }
    }
  }

  var showCursor = function(e) {
    window.addEventListener('mousemove', moveCursor);
    cache.sec4.removeEventListener('mouseenter', showCursor);
    cache.sec4.addEventListener('mouseleave', hideCursor);
    cache.cursor.style.display = 'block';
    cache.cursor.style.top = e.pageY + 'px';
    cache.cursor.style.left = e.pageX + 'px';
    anime({
      targets: cache.cursor,
      opacity: [0,1],
      duration: 300,
      easing: 'easeInOutQuad',
    });
  }

  var hideCursor = function() {
    cache.sec4.addEventListener('mouseenter', showCursor);
    cache.sec4.removeEventListener('mouseleave', hideCursor);
    window.removeEventListener('mousemove', moveCursor);
    cache.cursor.style.display = 'none';
  }

  var moveCursor = function(e) {
    cache.cursor.style.top = e.clientY + 'px';
    cache.cursor.style.left = e.clientX + 'px';
    if(e.pageX > (window.innerWidth-cache.cursor.offsetWidth)) {
      if(!cache.cursor.classList.contains('reverse')) {
        cache.cursor.classList.add('reverse');
      }
    }
    if(e.pageX < (window.innerWidth-cache.cursor.offsetWidth)) {
      if(cache.cursor.classList.contains('reverse')) {
        cache.cursor.classList.remove('reverse');
      }
    }
  }

     initFunc();
   };
   }
   export { Artiste }
