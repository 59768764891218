import utils from '../utils/utils'
import anime from '../libs/anime'
import Hammer from '../libs/hammer'
import globalVar from '../utils/globalVar'

class Register {
  constructor() {
  let cache = {};
  let self = this;
  let index = 0;
  const desktop = window.matchMedia("(min-width: 1300px)");
  const tablet = window.matchMedia("(max-width: 991px)");
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  let initFunc = () => {
    initCache();
    registerEvents();
  }

  let initCache = function() {
    cache.number = document.querySelectorAll('.ariane_form .form_sec_numb');
    cache.form = document.querySelector('.form form');
    cache.formSec = document.querySelectorAll('.form_sec');
    cache.nav_form_prev = cache.form.querySelector('.left .btn_round');
    cache.nav_form_nxt = cache.form.querySelector('.right .btn');
    cache.nav_form_submit = cache.form.querySelector('input[type="submit"]');
    cache.formEmail = cache.form.querySelector('input[name="useremail"]');
    cache.formOptIn = cache.form.querySelector('input[name="delight_optin"]');
  };

  var registerEvents = function() {
    cache.nav_form_nxt.addEventListener('click', nxtForm);
    cache.nav_form_prev.addEventListener('click', prevForm);

    if(email_nl) {
      cache.formEmail.value = email_nl;
      cache.formOptIn.checked = true;
    }


    acf.doAction( 'prepare' );

  };

  var nxtForm = function() {
    var validated = true;
    var fields = cache.formSec[index].querySelectorAll('input, select');

    for (var i = 0; i < fields.length; i++) {
      if(fields[i].hasAttribute('required') && (fields[i].value == "")) {
        validated = false;
        fields[i].style.borderColor = "#FF1E0A";
        fields[i].previousElementSibling.querySelector('.error').style.color = "#FF1E0A";
      }

      else if(fields[i].name == 'delight_optin') {
        console.log('EG');
        if(!fields[i].checked) {
          validated = false;
          fields[i].style.borderColor = "#FF1E0A";
          fields[i].parentNode.style.color = "#FF1E0A";
        }

      }

      else if(fields[i].hasAttribute('required') && (fields[i].value !== "")) {
        if(fields[i].classList.contains('min6') && (fields[i].value.length < 6)) {
          validated = false;
          fields[i].style.borderColor = "#FF1E0A";
          fields[i].previousElementSibling.querySelector('p').style.color = "#FF1E0A";
          fields[i].previousElementSibling.querySelector('p').style.fontSize = "14px";
          fields[i].previousElementSibling.querySelector('p').style.fontWeight = "800";
        }

        else if(fields[i].name == 'useremail') {
          if(!validateEmail(fields[i].value)) {
            validated = false;
            fields[i].style.borderColor = "#FF1E0A";
            fields[i].previousElementSibling.style.color = "#FF1E0A";
          }
          else {
            fields[i].style.borderColor = "#232323";
            fields[i].previousElementSibling.style.color = "#232323";
          }
        }

        else if(fields[i].name == 'password') {
          if(fields[i].value !== fields[(i+1)].value) {
            validated = false;
            fields[i].style.borderColor = "#FF1E0A";
            fields[i].previousElementSibling.style.color = "#FF1E0A";
            fields[(i+1)].style.borderColor = "#FF1E0A";
            fields[(i+1)].previousElementSibling.style.color = "#FF1E0A";
            fields[(i+1)].nextElementSibling.nextElementSibling.style.opacity = 1;
          }

        }

        else {
          if(fields[i].name !== 'password-check'){
            fields[i].style.borderColor = "#232323";
            fields[i].previousElementSibling.querySelector('.error').style.color = "#232323";
            if(fields[i].previousElementSibling.querySelector('p')) {
              fields[i].previousElementSibling.querySelector('p').style.color = "#232323";
              fields[i].previousElementSibling.querySelector('p').style.fontSize = "12px";
              fields[i].previousElementSibling.querySelector('p').style.fontWeight = "300";
            }
          }
        }
      }
    }

    if(validated) {
      var currentForm = cache.formSec[index];
      index++;
      var targetForm = cache.formSec[index]
      anime({
        targets: currentForm,
        translateX: [0, -100],
        opacity: [1,0],
        duration: 500,
        easing: 'easeOutSine',
      });

      targetForm.style.display = "block";
      anime({
        targets: targetForm,
        translateX: [100, 0],
        opacity: 1,
        duration: 500,
        easing: 'easeOutSine',
      });

      anime({
        targets: scrollElement,
        scrollTop: document.querySelector('.ariane_form').offsetTop,
        delay: 200,
        duration: 500,
        easing: 'easeInOutSine',
      });

      anime({
        targets: cache.form,
        delay: 200,
        height: (targetForm.offsetHeight + 110),
        duration: 500,
        easing: 'easeOutSine',
        complete: function() {
          currentForm.style.display = "none";
        }
      });
      cache.number[index].classList.add('active');

      if(index == 2) {
        anime({
          targets: cache.nav_form_nxt,
          opacity: 0,
          duration: 400,
          easing: 'easeOutSine',
          complete: function() {
            cache.nav_form_nxt.style.display = "none";
            cache.nav_form_submit.style.display = "block";
            anime({
              targets: cache.nav_form_submit,
              opacity: [0, 1],
              duration: 400,
              easing: 'easeOutSine',
            });
          }
        });
      }

      if(index == 1) {
        cache.nav_form_prev.classList.remove('not-active');
      }
    }
  }

  var prevForm = function() {
    var currentForm = cache.formSec[index];
    cache.number[index].classList.remove('active');
    index--;
    var targetForm = cache.formSec[index]
    anime({
      targets: currentForm,
      translateX: [0, 100],
      opacity: [1,0],
      duration: 500,
      easing: 'easeOutSine',
    });

    targetForm.style.display = "block";
    anime({
      targets: targetForm,
      translateX: [-100, 0],
      opacity: [0,1],
      duration: 500,
      easing: 'easeOutSine',
    });

    anime({
      targets: cache.form,
      delay: 200,
      height: (targetForm.offsetHeight + 110),
      duration: 500,
      easing: 'easeOutSine',
      complete: function() {
        currentForm.style.display = "none";
      }
    });
    anime({
      targets: scrollElement,
      scrollTop: document.querySelector('.ariane_form').offsetTop,
      delay: 200,
      duration: 500,
      easing: 'easeInOutSine',
    });

    if(index == 1) {
      anime({
        targets: cache.nav_form_submit,
        opacity: 0,
        duration: 400,
        easing: 'easeOutSine',
        complete: function() {
          cache.nav_form_nxt.style.display = "block";
          cache.nav_form_submit.style.display = "none";
          anime({
            targets: cache.nav_form_nxt,
            opacity: [0, 1],
            duration: 400,
            easing: 'easeOutSine',
          });
        }
      });
    }

    if(index == 0) {
      cache.nav_form_prev.classList.add('not-active');
    }
  }


  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  initFunc();
};
}
export { Register }
