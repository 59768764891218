class Billeterie {
  constructor() {
    let cache = {};
    let self = this;
    let message;

    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = function() {
      cache.iframe = document.querySelector("iframe");
    };

    let registerEvents = function() {
      window.addEventListener("message", (event) => {
        if (
          String(event.data).includes("weezuniq") &&
          String(event.data) !== message
        ) {
          message = String(event.data);
          let height = parseInt(message.split("-")[1]);
          cache.iframe.style.height = height + "px";
        }
      });
    };

    initFunc();
  }
}
export { Billeterie };
