import { Account } from './modules/Account';
import { Ajax } from './modules/Ajax';
import { Apropos } from './modules/Apropos';
import { Artiste } from './modules/Artiste';
import { ArtisteArch } from './modules/ArtisteArch';
import { Billeterie } from './modules/Billeterie';
import { ConcertLanding } from './modules/ConcertLanding';
import { Concerts } from './modules/Concerts';
import { Concours } from './modules/Concours';
import { Contact } from './modules/Contact';
import { Font } from './modules/Font';
import { GlobalJs } from './modules/GlobalJs';
import { Home } from './modules/Home';
import { HoverImg } from './modules/HoverImg';
import { Nav } from './modules/Nav';
import { QuatreCentQuatre } from './modules/QuatreCentQuatre';
import { Register } from './modules/Register';
import { SliderVideo } from './modules/SliderVideo';

class App {
  constructor() {
    const nav = new Nav();
    const font = new Font();
    let loaderHome = true;
    let billet = false;

    if (document.querySelector(".template-concerts") && !billet) {
      billet = true;
      const concerts = new Concerts();
    }
    if (document.querySelector(".hover_img")) {
      const hoverImg = new HoverImg();
    }
    if (document.querySelector(".template-home")) {
      const home = new Home();
    }
    if (document.querySelector(".template-apropos")) {
      const apropos = new Apropos();
    }
    if (document.querySelector(".template-contact")) {
      const contact = new Contact();
    }
    if (document.querySelector(".template-archive-artiste")) {
      const artisteArch = new ArtisteArch();
    }
    if (document.querySelector(".template-single-artiste")) {
      const artiste = new Artiste();
    }
    if (document.querySelector(".template-single-artiste .slider_video")) {
      const sliderVideo = new SliderVideo();
    }
    if (document.querySelector(".quatre-cent-quatre")) {
      const quatreCentQuatre = new QuatreCentQuatre();
    }
    if (document.querySelector(".template-register .form")) {
      const register = new Register();
    }
    if (document.querySelector(".template-account form")) {
      const account = new Account();
    }
    if (document.querySelector(".template-archive-concours, .template-single-concours")) {
      const concours = new Concours();
    }
    if (document.querySelector(".template-single-concert")) {
      const billeterie = new Billeterie();
    }
    if (document.querySelector(".template-billeterie")) {
      const concertLanding = new ConcertLanding();
    }
    const ajax = new Ajax();
  }
}

window.App = App;

window.onload = function() {
  let app = new App();
};
